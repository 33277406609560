import { Redirect, Router } from '@reach/router';
import React from 'react';

import Layout from '../../components/Layout/Layout';
import ProtectedRoute from '../../components/ProtectedRoute';
import roles from '../../constans/roles';
import CompleteProfile from '../../views/complete-profile';
import CustomersBookings from '../../views/customer-bookings/list';
import EditCustomerPersonal from '../../views/customer-settings/EditCustomerPersonal/EditCustomerPersonal';
import EditCustomerPassword from '../../views/customer-settings/EditCustomerPassword/EditCustomerPassword';
import EditCustomerEmail from '../../views/customer-settings/EditCustomerEmail/EditCustomerEmail';
import EditCustomerSearchProfile
  from '../../views/customer-settings/EditCustomerSearchProfile/EditCustomerSearchProfile';
import CreateBooking from '../../views/customer-bookings/create/CreateBooking';
import BookingDetails from '../../views/customer-bookings/details/BookingDetails';
import EditBooking from '../../views/customer-bookings/edit/EditBooking';

const Customer = () => (
  <Layout>
    <Router basepath="/customers">
      <ProtectedRoute
        path="/search-profile"
        availableRoles={[roles.RETAIL_CUSTOMER]}
        component={EditCustomerSearchProfile}
      />
      <ProtectedRoute
        path="/settings/email"
        availableRoles={[roles.RETAIL_CUSTOMER]}
        component={EditCustomerEmail}
      />
      <ProtectedRoute
        path="/settings/password"
        availableRoles={[roles.RETAIL_CUSTOMER]}
        component={EditCustomerPassword}
      />
      <ProtectedRoute
        path="/settings/profile-info"
        availableRoles={[roles.RETAIL_CUSTOMER]}
        component={EditCustomerPersonal}
      />
      <ProtectedRoute
        path="/bookings/create"
        availableRoles={[roles.RETAIL_CUSTOMER]}
        component={CreateBooking}
      />
      <ProtectedRoute
        path="/bookings/:bookingId"
        availableRoles={[roles.RETAIL_CUSTOMER]}
        component={BookingDetails}
      />
      <ProtectedRoute
        path="/bookings/:bookingId/edit"
        availableRoles={[roles.RETAIL_CUSTOMER]}
        component={EditBooking}
      />
      <ProtectedRoute
        path="/complete-profile"
        availableRoles={[roles.RETAIL_CUSTOMER]}
        component={CompleteProfile}
      />
      <ProtectedRoute
        path="/bookings"
        availableRoles={[roles.RETAIL_CUSTOMER]}
        component={CustomersBookings}
      />
      <Redirect default noThrow from="*" to="/customers/complete-profile" />
    </Router>
  </Layout>
);

export default Customer;
