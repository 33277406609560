import React, { useState } from 'react';

import RoomItemSlider from '../RoomItemSlider/RoomItemSlider';
import AmenitiesModal from '../AmenitiesModal/AmenitiesModal';
import IconPlaceholder from '../../../assets/img/placeholder.svg';
import { DISPLAYED_SEARCH_RESULT_FEATURES, PROPERTIES_PER_PAGE } from '../../../constans/search';
import { CANCELLATION_DEFAULT_SELECTED_OPTION } from '../../../constans/price-and-taxes';
import { pvPipe, formatCurrency, toggleBodyStylesForModal } from '../../../helpers/helper-methods';

import styles from './SearchResultOffer.module.scss';

const SearchResultOffer = ({
  id,
  PV,
  title,
  totalPrice,
  features,
  displayedFeatures = DISPLAYED_SEARCH_RESULT_FEATURES,
  descriptionClassName,
  wrapperClassName,
  hideHeader,
  hideFooter,
  pricePerNight,
  room,
  index,
  currencyCode,
  handleBookClick,
  cancellationPenaltyType,
}) => {
  const [isModalShown, setIsModalShown] = useState(false);
  const { photos } = room;

  const toggleModal = () => {
    toggleBodyStylesForModal();
    setIsModalShown((prevState) => !prevState);
  };

  return (
    <div className={`${styles.offer} ${wrapperClassName}`}>
      {!hideHeader && (
        <>
          <div className="flex flex-between">
            <div className={styles.offerPv}>
              {pvPipe(index, PV, PROPERTIES_PER_PAGE, 0)}
            </div>
            <p className={styles.offerPrice}>
              Total price:
              {currencyCode && totalPrice ? (
                <span>
                  {formatCurrency(currencyCode, totalPrice)}
                </span>
              ) : null}
            </p>
          </div>
          <div className="flex flex-v-center flex-between">
            <div className={`${styles.offerTitle} text-truncate`}>
              {title}
            </div>
            <button
              type="button"
              className={`${styles.offerButton} btn btn_common btn-uppercased t-600`}
              onClick={() => handleBookClick(id)}
            >
              book
            </button>
          </div>
          <div className={styles.divider} />
        </>
      )}
      <div className={`${styles.offerContent}`}>
        {photos?.length > 0
          ? (
            <RoomItemSlider
              wrapperStyles={styles.sliderWrapper}
              slideStyles={styles.sliderItem}
              photosArr={photos}
              withChip={cancellationPenaltyType === CANCELLATION_DEFAULT_SELECTED_OPTION}
            />
          )
          : (
            <div className={styles.sliderWrapper}>
              <img className={`${styles.sliderItem} ${styles.sliderPlaceholder}`} src={IconPlaceholder} alt="Room placeholder" />
            </div>
          )}
        <div className={styles.offerRoom}>
          <div className="flex flex-between flex-wrap">
            <div className={`${styles.offerRoomTitle} text-truncate`}>{room?.title}</div>
            {!hideHeader && (
              <p className={styles.offerPrice}>
                <span className={styles.offerRoomPrice}>
                  {(currencyCode && pricePerNight) && formatCurrency(currencyCode, pricePerNight)}
                </span>
                / night
              </p>
            )}
          </div>
          <div className={`${descriptionClassName} ${styles.offerRoomDescription}`}>
            {room?.description}
          </div>

          <div className={styles.dividerSmall} />

          <div className={styles.offerRoomFeatures}>
            {!!features?.length && (
              <>
                <div className={styles.offerRoomFeaturesContent}>
                  {features
                    .slice(0, displayedFeatures)
                    .map(({ title: featureTitle }, idx) => (
                      <div key={idx} className={styles.offerRoomFeature}>
                        <div className={styles.dot} />
                        {featureTitle}
                      </div>
                    ))}
                  <button
                    type="button"
                    onClick={toggleModal}
                    className={`${styles.offerRoomFeatureRest} btn`}
                  >
                    {`See other - ${features.length - displayedFeatures}`}
                  </button>
                </div>
                <AmenitiesModal
                  showModal={isModalShown}
                  amenities={features}
                  toggleModal={toggleModal}
                />
              </>
            )}
          </div>
          {!hideFooter && (
            <div className={styles.mobileFooter}>
              <p className={styles.offerPrice}>
                Total price:
                <span>
                  {formatCurrency(currencyCode, totalPrice)}
                </span>
              </p>

              <button
                type="button"
                className={`${styles.offerButton} btn btn_common btn-uppercased t-600`}
                onClick={handleBookClick}
              >
                book
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
export default SearchResultOffer;
