import React, { useMemo } from 'react';
import { navigate } from 'gatsby';

import SearchProfile from '../../../components/Users/SearchProfile/SearchProfile';

import styles from './EditCustomerSearchProfile.module.scss';
import UsersService from '../../../services/usersService';
import { LOCALSTORAGE_KEYS } from '../../../constans/localstorage';
import StorageService from '../../../services/storage.service';

const storageService = StorageService();

const EditCustomerSearchProfile = () => {
  const profile = useMemo(() => storageService.get(LOCALSTORAGE_KEYS.USER), []);

  const handleSubmit = async (values) => {
    const { data } = await UsersService()
      .updateSearchProfile(values);

    const {
      // eslint-disable-next-line no-unused-vars
      id, createdAt, updatedAt, ...searchProfile
    } = data;

    storageService.set(LOCALSTORAGE_KEYS.USER, { ...profile, searchProfile });
    navigate('/');
  };

  return (
    <div className={`${styles.contentWrapper} customer-page-wrapper container`}>
      <div className="row">
        <div className="col-9">
          <div className={styles.header}>Account profile</div>
          <div className="edit-form-wrapper edit-from-mobile">
            <SearchProfile
              onSubmit={handleSubmit}
              submitBtnStyles={styles.submitBtn}
              isRedirectToSearch={false}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditCustomerSearchProfile;
