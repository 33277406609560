import React from 'react';
import moment from 'moment';
import { Link } from 'gatsby';

import { DEFAULT_FORMAT } from '../../../constans/formats';
import CalendarIcon from '../../../assets/img/icons/ic-calendar-disable.svg';
import PdfIcon from '../../../assets/img/icons/ic-pdf.svg';
import SpinnerIcon from '../../../assets/img/icons/ic-spinner-colorable.svg';
import styles from './CustomersBookingsItem.module.scss';
import { formatCurrency } from '../../../helpers/helper-methods';
import LogoImg from '../../../assets/img/placeholder.svg';
import {
  BOOKING_PAYMENT_STATUS_MAP,
  BOOKING_PAYMENT_STATUSES,
  BOOKINGS_STATUSES,
  BOOKING_STATUSES_MAP,
} from '../../../constans/bookings';
import { PAYMENT_TERMS } from '../../../constans/payments';

const CustomersBookingsItem = ({
  dueDateHide,
  bookingData,
  downloadPdf,
  isPdfLoading,
}) => {
  const isPayed = bookingData.paymentStatus === BOOKING_PAYMENT_STATUSES.PAID;
  const isCancelled = bookingData.status === BOOKINGS_STATUSES.CANCELED;

  const labelText = isCancelled
    ? BOOKING_STATUSES_MAP.CANCELED.text
    : BOOKING_PAYMENT_STATUS_MAP[bookingData?.paymentStatus].text;

  const labelClassName = isCancelled
    ? BOOKING_STATUSES_MAP.CANCELED.background
    : BOOKING_PAYMENT_STATUS_MAP[bookingData?.paymentStatus].background;

  return (
    <div className={`${styles.bookItem} flex`}>
      <div className={styles.imgWrapper}>
        <div
          className={`
            ${styles.status}
            ${labelClassName}
            status-item
          `}
        >
          {labelText}
        </div>
        <img
          src={bookingData?.offer?.property?.photos?.[0]?.file?.link || LogoImg}
          alt="img"
        />
      </div>
      <div className={styles.infoWrapper}>
        <div
          className={`${styles.status} ${labelClassName} status-item`}
        >
          {labelText}
        </div>
        <div className={`${styles.title} h5-title`}>
          <Link to={`${bookingData.id}`} className="h5-title">
            {bookingData?.offer?.property?.title || ''}
          </Link>
        </div>
        <div className={`${styles.infoWrapperMain} flex flex-h-start flex-between`}>
          <div className={`${styles.roomType} ${styles.bordered} t-500`}>
            {bookingData?.offer?.room?.title || ''}
          </div>
          {dueDateHide && !isPayed && (
            <>
              {bookingData?.offer?.paymentTermsType === PAYMENT_TERMS.AT_TIME_OF_BOOKING && (
                <div className={`${styles.dueDateWrapper} ${styles.bordered}`}>
                  <div className={`${styles.dueDateText} t-500`}>Payment due date:</div>
                  <div className={`${styles.dueDateValue} t-600`}>
                    {moment(bookingData?.createdAt).format(DEFAULT_FORMAT)}
                  </div>
                </div>
              )}
              {bookingData?.offer?.paymentTermsType === PAYMENT_TERMS.BEFORE_CHECKIN_DATE && (
                <div className={`${styles.dueDateWrapper} ${styles.bordered}`}>
                  <div className={`${styles.dueDateText} t-500`}>Payment due date:</div>
                  <div className={`${styles.dueDateValue} t-600`}>
                    {moment(bookingData?.startDate)
                      .subtract(bookingData?.offer?.paymentDeadlineInDays, 'days')
                      .format(DEFAULT_FORMAT)}
                  </div>
                </div>
              )}
              {bookingData?.offer?.paymentTermsType === PAYMENT_TERMS.PAYMENT_AT_THE_PROPERTY && (
                <div className={`${styles.dueDateWrapper} ${styles.bordered}`}>
                  <div className={`${styles.dueDateText} t-500`}>Payment due date:</div>
                  <div className={`${styles.dueDateValue} t-600`}>
                    {moment(bookingData?.startDate).format(DEFAULT_FORMAT)}
                  </div>
                </div>
              )}
            </>
          )}
        </div>
        <div className={`${styles.infoWrapperFooter} flex flex-v-center flex-between`}>
          <div className={`${styles.dateDetailsWrapper} flex flex-h-start`}>
            <div className={`${styles.dateWrapper} flex flex-h-start`}>
              <CalendarIcon className={styles.icon} />
              <div className={`${styles.date} t-500`}>
                {moment(bookingData.startDate).format(DEFAULT_FORMAT)}
                <span className={styles.delimiter}>-</span>
                {moment(bookingData.endDate).format(DEFAULT_FORMAT)}
              </div>
            </div>
            {isPdfLoading ? (
              <div className={`${styles.loadingWrapper} flex flex-v-center t-500`}>
                <SpinnerIcon className={styles.icon} />
                <span>Loading...</span>
              </div>
            ) : (
              <div
                className={`${styles.detailsLinkWrapper} flex flex-v-center slide-line primary uppercased-text t-500`}
                onClick={() => downloadPdf(bookingData)}
              >
                <PdfIcon className={styles.icon} />
                <span>PDF</span>
              </div>
            )}

          </div>
          {bookingData?.currency?.code ? (
            <div className={styles.priceWrapper}>
              <span className={`${styles.priceWrapperText} t-500`}>
                Total price:
              </span>
              <span className={`${styles.priceWrapperValue} t-700`}>
                {formatCurrency(bookingData?.currency?.code, bookingData.totalPrice)}
              </span>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

CustomersBookingsItem.defaultProps = {
  dueDateHide: false,
};

export default CustomersBookingsItem;
