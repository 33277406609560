import React, { useState, useEffect } from 'react';
import { navigate } from 'gatsby';

import FormStepper from '../../components/FormStepper/FormStepper';
import PersonalInfo from '../../components/Users/PersonalInfo/PersonalInfo';
import SearchProfile from '../../components/Users/SearchProfile/SearchProfile';
import StorageService from '../../services/storage.service';
import UsersService from '../../services/usersService';
import { LOCALSTORAGE_KEYS } from '../../constans/localstorage';

const storageService = StorageService();
const getUser = storageService.get(LOCALSTORAGE_KEYS.USER);
const navItems = [
  { key: 'personalInfo', navText: 'Personal info', component: PersonalInfo },
  { key: 'profile', navText: 'Profile', component: SearchProfile },
];

const CompleteProfile = () => {
  const [activeNavIndex, setActiveNavIndex] = useState(0);

  const switchNextTab = () => {
    setActiveNavIndex((prevState) => prevState + 1);
  };

  const handleSubmit = async (values) => {
    const { data } = await UsersService()
      .updateSearchProfile(values);

    const {
      // eslint-disable-next-line no-unused-vars
      id, createdAt, updatedAt, ...searchProfile
    } = data;

    storageService.set(LOCALSTORAGE_KEYS.USER, { ...getUser, searchProfile });
    navigate('/');
  };

  useEffect(() => {
    if (getUser.searchProfile) {
      navigate('/search');
    }
  }, []);

  const TabToDisplay = navItems[activeNavIndex].component;
  return (
    <div className="customer-page-wrapper container">
      <div className="h3-title t-700 seller-title">Complete Profile</div>
      <div className="row">
        <div className="col-lg-3">
          <FormStepper
            activeNavIndex={activeNavIndex}
            navItems={navItems}
          />
        </div>
        <div className="col-12 col-lg-9">
          <div className="edit-form-wrapper edit-from-mobile">
            <TabToDisplay
              onSubmit={handleSubmit}
              switchNextTab={switchNextTab}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompleteProfile;
