import React, { useEffect, useRef, useState } from 'react';

import InputWrapper from '../InputWrapper/InputWrapper';
import Label from '../Label/Label';
import useOutsideClick from '../../../hooks/useOutsideClick';
import useDebounce from '../../../hooks/useDebounce';
import { ENTER_KEY } from '../../../constans/validation';
import { FIRST_SUGGESTIONS_INDEX } from '../../../constans/search';

import styles from './InputAutocomplete.module.scss';

const AutocompleteItem = ({
  onClick,
  prefixIcon,
  displayedValue,
  dropdownItemStyles,
  dropdownItemTextStyles,
}) => (
  <button
    onClick={onClick}
    type="button"
    className={`${styles.suggestionsOption} ${dropdownItemStyles}`}
  >
    {prefixIcon}
    <div className={`${dropdownItemTextStyles} text-truncate`}>
      {displayedValue}
    </div>
  </button>
);

const InputAutocomplete = ({
  children,
  placeholder,
  type,
  className,
  label,
  labelClassName,
  wrapperClassName,
  onChange,
  onFocus,
  onSelect,
  prefixIcon,
  setFieldValue,
  name,
  dropdownStyles,
  dropdownItemStyles,
  dropdownItemTextStyles,
  inputInitialValue,
  errorElement,
  flipped,
  getResultValue = (value) => (value),
}) => {
  const [searchResults, setSearchResults] = useState([]);
  const [inputValue, setInputValue] = useState(inputInitialValue);
  const [isShown, setIsShown] = useState(false);
  const wrapperRef = useRef(null);
  const debouncedQuery = useDebounce(inputValue, 300);

  useEffect(() => {
    setInputValue(inputInitialValue);
  }, [inputInitialValue]);

  const handleSelect = async (value) => {
    if (onSelect) {
      onSelect(value);
    }

    setInputValue(value);

    setIsShown(false);
  };

  const handleEnterKeyPress = (event) => {
    const { target, key } = event;

    if (key === ENTER_KEY && searchResults?.length) {
      event.preventDefault();

      handleSelect(getResultValue(searchResults[FIRST_SUGGESTIONS_INDEX]));

      if (target) {
        target.blur();
      }
    }
  };

  const handleSearch = (searchValue) => {
    if (searchValue?.length) {
      onChange(searchValue)
        .then(({ data }) => setSearchResults(data));
    } else {
      setSearchResults([]);
    }
  };

  const handleChange = ({ target }) => {
    const { value } = target;

    setInputValue(value);
  };

  const handleFocus = (event) => {
    const { target: { value } } = event;
    if (onFocus) {
      onFocus();
    }

    setIsShown(true);
    handleSearch(value);
  };

  useOutsideClick(wrapperRef, () => {
    if (!isShown) {
      return;
    }

    if (searchResults.length) {
      const value = getResultValue(searchResults[FIRST_SUGGESTIONS_INDEX]);

      handleSelect(value);

      return;
    }

    handleSelect('');
  });

  useEffect(() => {
    handleSearch(debouncedQuery);
    setFieldValue(name, debouncedQuery);
  }, [debouncedQuery]);

  return (
    <InputWrapper ref={wrapperRef} containerClasses={wrapperClassName}>
      <Label containerStyles={labelClassName}>{label}</Label>
      <input
        value={inputValue}
        placeholder={placeholder}
        type={type}
        className={`${className} t-500`}
        onFocus={handleFocus}
        onKeyPress={handleEnterKeyPress}
        onChange={handleChange}
      />
      {children}
      {errorElement}
      <div
        className={`
          ${dropdownStyles}
          ${styles.transition}
          ${styles.suggestions}
          ${flipped && styles.toTop}
          ${!!searchResults.length && isShown && `${styles.suggestionsShown} filled`}
        `}
      >
        {searchResults.map((suggestion, index) => (
          <AutocompleteItem
            dropdownItemStyles={dropdownItemStyles}
            dropdownItemTextStyles={dropdownItemTextStyles}
            key={suggestion?.id || `${suggestion}-${index}`}
            prefixIcon={prefixIcon}
            displayedValue={getResultValue(suggestion)}
            onClick={() => handleSelect(getResultValue(suggestion))}
          />
        ))}
      </div>
    </InputWrapper>
  );
};

InputAutocomplete.defaultProps = {
  dropdownStyles: '',
  dropdownItemStyles: '',
  dropdownItemTextStyles: '',
  inputInitialValue: '',
  errorElement: null,
};

export default InputAutocomplete;
