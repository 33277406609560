import React, { useState } from 'react';
import { object } from 'yup';
import toastr from 'toastr';
import { Formik, Form } from 'formik';
import http from '../../../services/http.service';
import CustomerSettingsWrapper from '../../../components/Users/CustomerSettingsWrapper/CustomerSettingsWrapper';
import InputWrapper from '../../../components/UI/InputWrapper/InputWrapper';
import { Input } from '../../../components/UI/Input/Input';
import Label from '../../../components/UI/Label/Label';
import Button from '../../../components/UI/Button/Button';
import CancelLinkBtn from '../../../components/UI/CancelLinkBtn/CancelLinkBtn';
import styles from './EditCustomerEmail.module.scss';
import { CUSTOMER_EMAIL } from '../../../constans/form-fields';
import StorageService from '../../../services/storage.service';
import { LOCALSTORAGE_KEYS } from '../../../constans/localstorage';
import { API } from '../../../constans/http';
import { SUCCESS_SEND_MSG } from '../../../constans/texts/texts';
import {
  passwordValidationSchemaCustomError,
  emailValidationSchema,
} from '../../../constans/validation-schemas';

const initialValues = {
  password: '',
  email: '',
};

const validationSchema = object().shape({
  password: passwordValidationSchemaCustomError,
}).concat(emailValidationSchema);

const EditCustomerEmail = () => {
  const [userData] = useState(StorageService().get(LOCALSTORAGE_KEYS.USER));

  const formSubmitHandler = (values, { setSubmitting, resetForm }) => {
    http.post(API.CUSTOMERS_EMAIL, values)
      .then(() => {
        setSubmitting(false);
        resetForm(initialValues);
        toastr.success(SUCCESS_SEND_MSG);
      })
      .catch(() => {
        setSubmitting(false);
        resetForm(initialValues);
      });
  };

  return (
    <CustomerSettingsWrapper>
      <Formik
        validateOnChange={false}
        validateOnBlur={false}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={formSubmitHandler}
      >
        {({ values }) => (
          <Form>
            <div className={`${styles.title} property-edit-title`}>Change email</div>
            <div className="customer-setting-content-wrapper">
              {userData ? (
                <div className={`${styles.userEmailTitle} t-500`}>
                  Your current email
                  {' '}
                  {userData?.email}
                </div>
              ) : null}
              <InputWrapper containerClasses={styles.borderedBlock}>
                <Label>Password</Label>
                <Input
                  type="password"
                  placeholder={CUSTOMER_EMAIL.password.placeHolder}
                  name={CUSTOMER_EMAIL.password.name}
                />
              </InputWrapper>
              <InputWrapper>
                <Label>New email</Label>
                <Input
                  disabled={values?.password?.length < 1}
                  type="text"
                  placeholder={CUSTOMER_EMAIL.email.placeHolder}
                  name={CUSTOMER_EMAIL.email.name}
                />
              </InputWrapper>
              <Button
                type="submit"
                classes={`${styles.submitBtn} btn btn-uppercased btn_block btn_common t-600 mb-1em`}
              >
                <span className="btn__text">Save Changes</span>
              </Button>
              <CancelLinkBtn
                linkStyles="btn_block"
              />
            </div>
          </Form>
        )}
      </Formik>
    </CustomerSettingsWrapper>
  );
};

export default EditCustomerEmail;
