import http from './http.service';

const BookingsService = () => {
  const basePath = '/bookings';

  const cancelBooking = (bookingId) => http.delete(`${basePath}/${bookingId}`);
  const getBookingDetails = (bookingId) => http.get(`${basePath}/${bookingId}`);
  const getBookingsList = (model) => http.get(basePath, { params: model });
  const editBooking = (bookingId, model) => {
    const {
      startDate,
      endDate,
      ...otherFields
    } = model;

    const requestModel = {
      ...otherFields,
      checkIn: startDate,
      checkOut: endDate,
    };

    return http.put(`${basePath}/${bookingId}`, requestModel);
  };

  const createBooking = (model) => {
    const {
      title,
      startDate,
      endDate,
      kidGuests,
      adultGuests,
      ...otherFields
    } = model;

    const requestModel = {
      ...otherFields,
      prefix: title,
      checkIn: startDate,
      checkOut: endDate,
      numberOfKids: kidGuests,
      numberOfAdults: adultGuests,
    };

    return http.post(`${basePath}`, requestModel);
  };

  const getBookingPdf = (bookingId) => http.get(
    `${basePath}/${bookingId}/pdf`,
    {
      responseType: 'blob',
    },
  );

  return {
    getBookingDetails,
    createBooking,
    getBookingsList,
    editBooking,
    getBookingPdf,
    cancelBooking,
  };
};

export default BookingsService;
