import React, { useState, useEffect, useCallback } from 'react';
import { Form, Formik } from 'formik';
import toastr from 'toastr';
import { object, string, date } from 'yup';
import { DatePicker } from 'antd';
import moment from 'moment';
import http from '../../../services/http.service';
import Button from '../../UI/Button/Button';
import CancelLinkBtn from '../../UI/CancelLinkBtn/CancelLinkBtn';
import InputWrapper from '../../UI/InputWrapper/InputWrapper';
import Label from '../../UI/Label/Label';
import { CustomPhoneInput, Input } from '../../UI/Input/Input';
import CustomSelect from '../../UI/CustomSelect/CustomSelect';
import GoogleAutocomplete from '../../UI/GoogleAutocomplete/GoogleAutocomplete';
import InputAutocomplete from '../../UI/InputAutocomplete/InputAutocomplete';
import IconCalendar from '../../../assets/img/icons/ic-calendar-active.svg';
import StorageService from '../../../services/storage.service';
import { DEFAULT_FORMAT } from '../../../constans/formats';
import { API } from '../../../constans/http';
import { NATIONALITY_LIMIT } from '../../../constans/pagination';
import { SUCCESS_SEND_MSG } from '../../../constans/texts/texts';
import { COMPLETE_PROFILE, ADDRESS_LOCATION } from '../../../constans/form-fields';
import {
  REGEXP_NOSPACES,
  USER_PROFILE_TEXT_FIELD_MAX,
  ADULTHOOD_MIN_AGE,
  BIRTHDATE_MAIN_MIN,
  PASSPORT_ID_MIN,
  PASSPORT_ID_MAX,
} from '../../../constans/validation';
import {
  BIRTH_DATE_MAX,
  BIRTH_DATE_REQUIRED,
  NICKNAME_INVALID,
  USER_PASSPORT_INVALID,
  USER_TITLE_REQUIRED,
  ADDRESS_REQUIRED,
  ADDRESS_IS_INVALID,
  BIRTH_DATE_ADULT_MIN,
  NATIONALITY_REQUIRED,
} from '../../../constans/error-messages';
import inputStyles from '../../UI/Input/Input.module.scss';
import { LOCALSTORAGE_KEYS } from '../../../constans/localstorage';
import styles from './PersonalInfo.module.scss';
import { customerPersonalInfoBaseValidationSchema, phoneValidationSchema } from '../../../constans/validation-schemas';

const validationSchema = object().shape({
  nationality: string().required(NATIONALITY_REQUIRED),
  title: string().required(USER_TITLE_REQUIRED),
  nickName: string().matches(REGEXP_NOSPACES, NICKNAME_INVALID),
  passportId: string()
    .max(PASSPORT_ID_MAX, USER_PASSPORT_INVALID)
    .min(PASSPORT_ID_MIN, USER_PASSPORT_INVALID)
    .matches(REGEXP_NOSPACES, USER_PASSPORT_INVALID),
  birthDate: date()
    .required(BIRTH_DATE_REQUIRED)
    .test(
      'MaxDate',
      BIRTH_DATE_MAX,
      (value) => moment().year() - moment(value).year() < BIRTHDATE_MAIN_MIN,
    )
    .test(
      'MinDate',
      BIRTH_DATE_ADULT_MIN,
      (value) => moment().year() - moment(value).year() > ADULTHOOD_MIN_AGE,
    ),
  location: object().shape({
    locationName: string().required(ADDRESS_REQUIRED)
      .matches(REGEXP_NOSPACES, ADDRESS_IS_INVALID),
  }),
}).concat(customerPersonalInfoBaseValidationSchema).concat(phoneValidationSchema);

const PersonalInfo = ({
  switchNextTab, submitBtnTitle, titleText, showCancelBtn,
}) => {
  const [initialValues, setInitialValues] = useState({
    title: '',
    nickName: '',
    firstName: '',
    lastName: '',
    passportId: '',
    birthDate: '',
    nationality: '',
    phone: '',
    location: { ...ADDRESS_LOCATION },
  });

  const getNationalities = (queryValue) => (
    http.get(API.NATIONALITY, {
      params: {
        offset: 0,
        limit: NATIONALITY_LIMIT,
        title: queryValue,
      },
    })
  );

  const getNationality = useCallback((value) => (value?.title), []);

  const formSubmitHandler = ({ phone, ...values }, { _setSubmitting }) => {
    const formattedPhone = `+${phone}`;

    http.put(API.CUSTOMER_PROFILE_SETTINGS, { phone: formattedPhone, ...values })
      .then(() => {
        const getUser = StorageService().get(LOCALSTORAGE_KEYS.USER);
        const updatedUserData = { ...getUser };
        updatedUserData.firstName = values.firstName || '';
        updatedUserData.lastName = values.lastName || '';
        StorageService().set(LOCALSTORAGE_KEYS.USER, updatedUserData);
        toastr.success(SUCCESS_SEND_MSG);
        if (switchNextTab) {
          switchNextTab();
        }
      });
  };

  useEffect(() => {
    http.get(API.CUSTOMER_PROFILE_SETTINGS)
      .then(({ data }) => {
        const addressLocationObj = data?.location ? {
          locationName: data.location.locationName,
          placeId: data.location.placeId,
          longitude: data.location.longitude,
          latitude: data.location.latitude,
          country: data.location.country,
          region: data.location.region,
          city: data.location.city,
          postalCode: data.location.postalCode,
        } : null;

        const profileData = {
          ...initialValues,
          title: data?.title || '',
          nickName: data?.nickName || '',
          firstName: data?.firstName || '',
          lastName: data?.lastName || '',
          passportId: data?.passportId || '',
          birthDate: data?.birthDate || '',
          nationality: data?.nationality || '',
          phone: data?.phone ? data.phone.slice(1) : '',
          location: addressLocationObj || ADDRESS_LOCATION,
        };

        setInitialValues(profileData);
      });
  }, []);

  return (
    <Formik
      enableReinitialize
      onSubmit={formSubmitHandler}
      validateOnChange={false}
      validateOnBlur={false}
      validationSchema={validationSchema}
      initialValues={initialValues}
    >
      {({ values, errors, setFieldValue }) => {
        const handleDatePick = ($moment) => {
          setFieldValue(COMPLETE_PROFILE.birthDate.name, $moment.format());
        };
        return (
          <Form>
            <div className={`${styles.title} property-edit-title`}>{titleText}</div>
            <div className="row">
              <InputWrapper containerClasses="col-md-12 col-lg-6">
                <Label>Nickname</Label>
                <Input
                  maxLength={USER_PROFILE_TEXT_FIELD_MAX}
                  type="text"
                  name={COMPLETE_PROFILE.nickName.name}
                  placeholder={COMPLETE_PROFILE.nickName.placeholder}
                />
              </InputWrapper>
              <InputWrapper containerClasses="col-md-12 col-lg-6">
                <Label>Title</Label>
                <CustomSelect
                  name={COMPLETE_PROFILE.titleSelect.name}
                  placeholder={COMPLETE_PROFILE.titleSelect.placeholder}
                  options={COMPLETE_PROFILE.titleSelect.options}
                />
              </InputWrapper>
              <InputWrapper containerClasses="col-md-12 col-lg-6">
                <Label>First name</Label>
                <Input
                  maxLength={USER_PROFILE_TEXT_FIELD_MAX}
                  type="text"
                  name={COMPLETE_PROFILE.firstName.name}
                  placeholder={COMPLETE_PROFILE.firstName.placeholder}
                />
              </InputWrapper>
              <InputWrapper containerClasses="col-md-12 col-lg-6">
                <Label>Last name</Label>
                <Input
                  maxLength={USER_PROFILE_TEXT_FIELD_MAX}
                  type="text"
                  name={COMPLETE_PROFILE.lastName.name}
                  placeholder={COMPLETE_PROFILE.lastName.placeholder}
                />
              </InputWrapper>
              <InputWrapper containerClasses="col-md-12 col-lg-6 z-index-10">
                <Label>Mobile phone</Label>
                <CustomPhoneInput
                  name={COMPLETE_PROFILE.phone.name}
                />
              </InputWrapper>
              <InputWrapper containerClasses="col-md-12 col-lg-6">
                <Label>Birth date</Label>
                <DatePicker
                  size="large"
                  className={`${errors?.birthDate ? 'errored' : ''} date-picked-input`}
                  format={DEFAULT_FORMAT}
                  placeholder={COMPLETE_PROFILE.birthDate.placeholder}
                  value={values.birthDate ? moment(values.birthDate) : null}
                  suffixIcon={<IconCalendar />}
                  allowClear={false}
                  onChange={handleDatePick}
                  styles={{
                    borderColor: '#000',
                  }}
                />
                {errors?.birthDate && <div className="error-input-msg">{errors.birthDate}</div>}
              </InputWrapper>
              <InputWrapper containerClasses="col-md-12 col-lg-6">
                <Label>Passport or ID</Label>
                <Input
                  maxLength={PASSPORT_ID_MAX}
                  type="text"
                  name={COMPLETE_PROFILE.passportId.name}
                  placeholder={COMPLETE_PROFILE.passportId.placeholder}
                />
              </InputWrapper>
              <InputAutocomplete
                inputInitialValue={values.nationality}
                onChange={getNationalities}
                dropdownStyles={`${styles.nationalityDropdown} autocomplete-dropdown-container`}
                dropdownItemStyles={`${styles.nationalityDropdownItem}`}
                wrapperClassName="col-md-12 col-lg-6"
                dropdownItemTextStyles="suggestion__text"
                placeholder={COMPLETE_PROFILE.nationality.placeholder}
                type="text"
                className={inputStyles.input}
                label="Nationality"
                getResultValue={getNationality}
                setFieldValue={setFieldValue}
                name={COMPLETE_PROFILE.nationality.name}
                errorElement={errors?.nationality ? (
                  <div className="error-input-msg">{errors.nationality}</div>
                ) : null}
              />
              <InputWrapper containerClasses="col-12">
                <GoogleAutocomplete
                  initialInputValue={values.location.locationName}
                  wrapperFieldValueName="location"
                  setWrapperFieldValue={setFieldValue}
                  placeholder="Select your address"
                  name="location.locationName"
                  type="text"
                >
                  <Label>Residential Address</Label>
                </GoogleAutocomplete>
                {errors?.location?.locationName && <div className="error-input-msg">{errors.location.locationName}</div>}
              </InputWrapper>
            </div>
            <div className={`${styles.formFooter} ${showCancelBtn ? styles.withCancel : ''} flex flex-h-end`}>
              {showCancelBtn ? (
                <CancelLinkBtn
                  linkStyles={`${styles.cancelBtn} mr-auto btn_mld`}
                />
              ) : null}
              <Button
                type="submit"
                classes={`${styles.submitBtn} ${showCancelBtn ? styles.withCancel : ''} btn btn_room-md btn-uppercased btn_common t-600`}
              >
                <span>{submitBtnTitle}</span>
              </Button>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

PersonalInfo.defaultProps = {
  switchNextTab: null,
  submitBtnTitle: 'Save & next',
  titleText: 'Personal info',
  showCancelBtn: false,
};

export default PersonalInfo;
