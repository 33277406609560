import React from 'react';

import Modal from '../UI/Modal/Modal';
import IconCalendarStart from '../../assets/img/icons/ic_calendar_star.svg';

import styles from './BookingSuccessModal.module.scss';

const BookingSuccessModal = ({ show, displayToggle, submitHandler }) => (
  <Modal modalClasses={styles.modal} showClose show={show} displayToggle={displayToggle}>
    <IconCalendarStart className={styles.calendar} />
    <p className={styles.title}>
      Done
    </p>
    <p className={styles.text}>
      Check your email or &quot;My Bookings&quot; section for details!
    </p>
    <button
      onClick={submitHandler}
      type="button"
      className={`${styles.button} btn btn_common t-600`}
    >
      Go to My Bookings
    </button>
  </Modal>
);

BookingSuccessModal.defaultProps = {
  submitHandler: () => {},
};
export default BookingSuccessModal;
