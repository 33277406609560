import React, { useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import {
  Elements,
  useStripe,
  useElements,
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement,
} from '@stripe/react-stripe-js';

import http from '../../services/http.service';
import Modal from '../UI/Modal/Modal';
import Button from '../UI/Button/Button';
import Label from '../UI/Label/Label';

import styles from './StripePaymentModal.module.scss';

const stripeInputOptions = {
  style: {
    base: {
      fontSize: '16px',
      fontWeight: 500,
      fontFamily: 'Montserrat',
      '::placeholder': {
        color: 'rgba(43, 43, 43, 0.4)',
      },
    },
  },
};

const CheckoutForm = ({
  show, displayToggle, bookingId, stripeSubmitCallback,
}) => {
  const [loading, setLoading] = useState(false);
  const [stripeError, setStripeError] = useState('');
  const stripe = useStripe();
  const elements = useElements();

  const stripeTokenHandler = ({ token }) => {
    const paymentData = token.id;

    // todo: find out if RC can pay for booking later
    if (bookingId) {
      return http.post(`bookings/${bookingId}/payment`, { cardToken: paymentData });
    }

    return paymentData;
  };

  const handleSubmit = async (event) => {
    try {
      setLoading(true);
      // We don't want to let default form submission happen here,
      // which would refresh the page.
      event.preventDefault();

      if (!stripe || !elements) {
        // Stripe.js has not yet loaded.
        // Make  sure to disable form submission until Stripe.js has loaded.
        return;
      }

      const card = elements.getElement(CardNumberElement);

      const result = await stripe.createToken(card);

      if (result?.error) {
        setStripeError(result?.error?.message);
        setLoading(false);
        return;
      }

      const token = await stripeTokenHandler(result);

      stripeSubmitCallback(token);

      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  };

  return (
    <Modal
      closeStyles={loading ? styles.disabled : ''}
      modalClasses={styles.modal}
      displayToggle={displayToggle}
      show={show}
    >
      <form onSubmit={handleSubmit}>
        <div className={`${styles.modalTitle} h4-title`}>
          Booking payment
        </div>
        <Label>Card number</Label>
        <CardNumberElement
          options={stripeInputOptions}
        />
        <Label>Expiry date</Label>
        <CardExpiryElement
          options={stripeInputOptions}
        />
        <Label>CV number</Label>
        <CardCvcElement
          options={stripeInputOptions}
        />
        {stripeError ? <div className="error-input-msg text-center mb-1em">{stripeError}</div> : null}
        <div className={`${styles.submitFooter} flex flex-between`}>
          <Button
            disabled={loading}
            type="button"
            onClick={displayToggle}
            classes={`${styles.cancelBtn} ${styles.btn} btn flex flex-center btn-uppercased btn_brs-28 btn_padded btn-uppercased btn_bordered t-700`}
          >
            <span>
              Cancel
            </span>
          </Button>
          <Button
            classes={`${styles.submitBtn} ${styles.btn} btn btn_brs-28 btn-uppercased btn_common t-600`}
            type="submit"
            disabled={!stripe || loading}
          >
            <span>
              Booking payment
            </span>
          </Button>
        </div>
      </form>
    </Modal>
  );
};

const stripePromise = loadStripe(process.env.STRIPE_PUB_KEY);

const StripePaymentModal = ({
  show, displayToggle, stripeSubmitCallback, bookingId,
}) => (
  <Elements stripe={stripePromise}>
    <CheckoutForm
      show={show}
      displayToggle={displayToggle}
      bookingId={bookingId}
      stripeSubmitCallback={stripeSubmitCallback}
    />
  </Elements>
);

export default StripePaymentModal;
