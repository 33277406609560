import React, { useState } from 'react';
import CustomersBookingsList from '../../../components/CustomersBookings/CustomersBookingsList/CustomersBookingsList';
import { BOOKINGS_STATUSES } from '../../../constans/bookings';
import styles from './index.module.scss';

const navTabs = [
  { navText: 'Upcoming', status: BOOKINGS_STATUSES.UPCOMING, showDueDate: true },
  { navText: 'Past', status: BOOKINGS_STATUSES.PAST, showDueDate: false },
];

const Index = () => {
  const [activeIndex, setActiveIndex] = useState(0);

  return (
    <div className="container section-padded-wrapper">
      <div className={`${styles.title} h3-title t-700`}>My bookings</div>
      <div className={`${styles.navListWrapper} horizontal-list-wrapper flex flex-shrink-0`}>
        <div className="flex flex-v-center flex-shrink-0">
          {navTabs.map((navItem, index) => (
            <div
              className={`
              ${activeIndex === index ? 'active' : ''} 
              ${styles.navItem} 
              horizontal-list-item t-600
            `}
              onClick={() => setActiveIndex(index)}
              key={`sdfs${index}`}
            >
              {navItem.navText}
            </div>
          ))}
        </div>
      </div>
      <CustomersBookingsList
        placeholderTitleStyles={styles.emptyListPlaceholder}
        activeIndex={activeIndex}
        status={navTabs[activeIndex].status}
        showDueDate={navTabs[activeIndex].showDueDate}
      />
    </div>
  );
};

export default Index;
