import React, { useState } from 'react';
import PlacesAutocomplete, { geocodeByPlaceId, getLatLng } from 'react-places-autocomplete';
import PropTypes from 'prop-types';
import { useField } from 'formik';
import googlePlaceParser from '../../../helpers/googlePlaceParser';
import { ADDRESS_LOCATION } from '../../../constans/form-fields';
import { REGEXP_CHECK_SPACES } from '../../../constans/validation';
import styles from '../Input/Input.module.scss';

const searchOptions = {
  types: ['address'],
};

const GoogleAutocomplete = ({
  children, initialInputValue, setWrapperFieldValue, wrapperFieldValueName, ...props
}) => {
  const { type, name, placeholder } = props;
  const [field, meta] = useField(props);
  const [locationAddress, setLocationAddress] = useState(initialInputValue);

  // eslint-disable-next-line consistent-return
  const handleSelect = async (address, placeId) => {
    try {
      const result = await geocodeByPlaceId(placeId);
      const place = result[0];
      if (place) {
        const addressData = googlePlaceParser(place);
        const latLongPlaceData = await getLatLng(place);
        const fullAddressData = {
          ...addressData,
          placeId,
          longitude: latLongPlaceData.lng,
          latitude: latLongPlaceData.lat,
        };
        setWrapperFieldValue(wrapperFieldValueName, { ...fullAddressData });
        setLocationAddress(fullAddressData.locationName);
      }
    } catch (error) {
      return error;
    }
  };

  const handleChange = (address) => {
    if (!REGEXP_CHECK_SPACES.test(address)) {
      setLocationAddress('');
      setWrapperFieldValue(wrapperFieldValueName, { ...ADDRESS_LOCATION });
      return;
    }
    setLocationAddress(address);
  };

  const onError = (status, clearSuggestions) => {
    clearSuggestions();
  };

  return (
    <PlacesAutocomplete
      {...field}
      debounce={400}
      value={locationAddress || meta.value}
      onChange={(address) => handleChange(address)}
      onSelect={(address, placeId) => handleSelect(address, placeId)}
      searchOptions={searchOptions}
      onError={onError}
    >
      {({
        getInputProps, suggestions, getSuggestionItemProps, loading,
      }) => (
        <div className="autocomplete-wrapper">
          {children}
          <input
            {...getInputProps({
              name,
              type,
              placeholder,
              className: `${meta.error && meta.touched ? styles.invalid : ''} ${styles.input}`,
            })}
          />
          <div className={`${suggestions?.length > 0 ? 'filled' : ''} autocomplete-dropdown-container`}>
            {loading && <div>Loading...</div>}
            {suggestions.map((suggestion, index) => {
              const className = suggestion.active
                ? 'suggestion__item--active'
                : 'suggestion__item';
              return (
                <div
                  {...getSuggestionItemProps(suggestion, {
                    className,
                  })}
                  key={`ofghare${index}`}
                >
                  <span className="suggestion__text">{suggestion.description}</span>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </PlacesAutocomplete>
  );
};

GoogleAutocomplete.defaultProps = {
  defaultAddress: '',
  initialInputValue: '',
  onChangeAddress: () => null,
  onSelectAddress: () => null,
};

GoogleAutocomplete.propTypes = {
  initialInputValue: PropTypes.string,
  defaultAddress: PropTypes.string,
  onChangeAddress: PropTypes.func,
  onSelectAddress: PropTypes.func,
};

export default GoogleAutocomplete;
