import React, { useEffect, useMemo, useState } from 'react';
import { navigate } from 'gatsby';
import { DatePicker, TimePicker } from 'antd';
import { Form, Formik } from 'formik';
import moment from 'moment';
import { object } from 'yup';

import StorageService from '../../../services/storage.service';
import PropertiesService from '../../../services/properties.service';
import BookingsService from '../../../services/bookings.service';
import InputWrapper from '../../../components/UI/InputWrapper/InputWrapper';
import Label from '../../../components/UI/Label/Label';
import CustomSelect from '../../../components/UI/CustomSelect/CustomSelect';
import BookingSuccessModal from '../../../components/BookingSuccessModal/BookingSuccessModal';
import { Input, CustomPhoneInput } from '../../../components/UI/Input/Input';
import SearchResultOffer from '../../../components/RoomItem/SearchResultOffer/SearchResultOffer';
import {
  getQueryParams,
  getDuration,
  formatCurrency,
  checkToDisableOfferEndDates,
  setDisableStartDates,
  removeEmptyObjectKeys,
} from '../../../helpers/helper-methods';
import OfferPriceCalculator from '../../../helpers/offerPriceCalculator';
import { LOCALSTORAGE_KEYS } from '../../../constans/localstorage';
import { DEFAULT_FORMAT, SEARCH_REQUEST_FORMAT, ROOM_SERVICE_TIME_FORMAT } from '../../../constans/formats';
import {
  BOOKING_DATEPICKER_STYLE,
  BOOKING_MINUTE_STEP,
  DISPLAYED_BOOKING_FEATURES,
  INITIAL_USER,
  INITIAL_VALUES,
} from '../../../constans/bookings';
import {
  bookingBaseValidationSchema,
  ageOfKidsValidationSchema,
  phoneValidationSchema,
} from '../../../constans/validation-schemas';
import {
  PAYMENT_AT_THE_PROPERTY,
  AFTER_BOOKING, FIXED_AMOUNT, PENALTY_TYPES, PERCENTAGE, TAX_TYPES_DETAILS_PAGE,
} from '../../../constans/price-and-taxes';
import IconCalendar from '../../../assets/img/icons/ic-calendar-active.svg';
import { titleSelect } from '../../../constans/form-fields';
import { CUSTOMER_BOOKINGS } from '../../../constans/links';
import { USER_PROFILE_TEXT_FIELD_MAX } from '../../../constans/validation';
import StripePaymentModal from '../../../components/StripePaymentModal/StripePaymentModal';
import Error from '../../../components/UI/Error/Error';
import { ADULT_PREFIX, KIDS_PREFIX } from '../../../constans/search';

import styles from './CreateBooking.module.scss';

const validationSchema = object()
  .concat(phoneValidationSchema)
  .concat(bookingBaseValidationSchema)
  .concat(ageOfKidsValidationSchema);

const CreateBooking = ({ location }) => {
  const [modalShown, setModalShown] = useState(false);
  const storageService = useMemo(() => StorageService(), []);
  const offersService = useMemo(() => PropertiesService(), []);
  const bookingsService = useMemo(() => BookingsService(), []);
  const [offer, setOffer] = useState();
  const [bookingId, setBookingId] = useState(null);
  const [cardToken, setCardToken] = useState();
  const [formValues, setFormValues] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [stripePaymentModal, setStripePaymentModal] = useState(false);

  const { termsAndCondition = '' } = offer || {};

  const isFixed = offer?.cancellationPenaltyType === FIXED_AMOUNT;
  const isPercentage = offer?.cancellationPenaltyType === PERCENTAGE;

  // Do not remove
  // ! initial values fixes 'changing an uncontrolled input of type text to be controlled' warning
  const {
    firstName = '',
    lastName = '',
    title = '',
    // passportId = '',
    email = '',
    phone = '',
  } = storageService.get(LOCALSTORAGE_KEYS.USER, INITIAL_USER);

  const {
    checkin,
    checkout,
    adultGuests,
    kidGuests,
    offerId,
    propertyId,
    kids = [],
    currency,
    numberOfRooms,
  } = getQueryParams(location.search);

  const getOfferDetails = async () => {
    const { data } = await offersService.getOfferDetails(propertyId, offerId, { currency });

    setOffer(data);
  };

  const displayGuests = (adultsAmount, kidsAmount = '') => `
      ${adultsAmount ? `${adultsAmount}${ADULT_PREFIX}` : ''} 
      ${kidsAmount > 0 ? `${adultsAmount ? '+' : ''} ${kidsAmount}${KIDS_PREFIX}` : ''}
    `;

  const toggleStripePaymentModal = () => setStripePaymentModal((prevState) => !prevState);

  const toggleSuccessModal = () => setModalShown(!modalShown);

  useEffect(() => {
    getOfferDetails();
  }, []);

  const bookOfferHandler = ({
    // eslint-disable-next-line no-unused-vars,no-shadow
    ageOfKids, stayDuration, phone, startDate, endDate, ...values
  }) => {
    const bookingData = removeEmptyObjectKeys({
      offerId,
      currency,
      ...values,
      startDate: moment(startDate).format(SEARCH_REQUEST_FORMAT),
      endDate: moment(endDate).format(SEARCH_REQUEST_FORMAT),
      phone: `+${phone.replace(/\+/, '')}`,
    });

    if (ageOfKids.length > 0) {
      bookingData.ageOfKids = ageOfKids;
    }

    return bookingsService.createBooking(bookingData)
      .then(({ data }) => {
        setBookingId(data?.id);
      })
      .then(() => {
        setIsLoading(false);
        toggleSuccessModal();
      })
      .catch(() => setIsLoading(false));
  };

  const formSubmitHandler = (values) => {
    if (offer?.paymentTermsType !== PAYMENT_AT_THE_PROPERTY) {
      setIsLoading(true);
      toggleStripePaymentModal();
      setFormValues(values);

      return;
    }

    setIsLoading(true);
    bookOfferHandler(values);
  };

  const newBookingDetailsNavigate = () => navigate(`${CUSTOMER_BOOKINGS}/${bookingId}`);

  const stripeSuccessCallback = (card) => setCardToken(card);

  useEffect(() => {
    if (formValues && cardToken) {
      setIsLoading(true);
      toggleStripePaymentModal();

      bookOfferHandler({ ...formValues, cardToken });
    }
  }, [cardToken]);

  return (
    <div className="container">
      <StripePaymentModal
        bookingId={bookingId}
        displayToggle={toggleStripePaymentModal}
        show={stripePaymentModal}
        stripeSubmitCallback={stripeSuccessCallback}
      />
      <div className={styles.wrapper}>
        <Formik
          validateOnChange={false}
          validateOnBlur={false}
          validationSchema={validationSchema}
          initialValues={{
            ...INITIAL_VALUES,
            numberOfRooms,
            stayDuration: getDuration({ checkout, checkin }),
            startDate: checkin,
            endDate: checkout,
            ageOfKids: kids,
            adultGuests,
            kidGuests,
            firstName,
            lastName,
            title,
            email,
            phone,
          }}
          onSubmit={formSubmitHandler}
        >
          {({
            values, errors, setFieldValue,
          }) => {
            const lengthOfStay = getDuration({
              checkout: values.endDate,
              checkin: values.startDate,
            });

            let subTotalPrice;
            let taxPrice;
            if (offer) {
              const result = OfferPriceCalculator.getOfferPrice(offer,
                {
                  lengthOfStay,
                  numberOfAdults: values.adultGuests,
                  numberOfKids: values.kidGuests,
                  taxes: offer?.taxes,
                  numberOfRooms,
                });

              subTotalPrice = result.subTotalPrice;
              taxPrice = result.taxPrice;
            }

            return (
              <Form>
                <div className={styles.section}>
                  <BookingSuccessModal
                    submitHandler={newBookingDetailsNavigate}
                    show={modalShown}
                    displayToggle={toggleSuccessModal}
                  />
                  <h1 className={styles.title}>Booking details</h1>
                  <hr className={styles.divider} />
                  <div className="row">
                    <InputWrapper containerClasses="col-12 col-md-6 col-lg-4">
                      <Label>Check-In date</Label>
                      <DatePicker
                        size="large"
                        format={DEFAULT_FORMAT}
                        placeholder="Start date"
                        className="date-picked-input"
                        suffixIcon={<IconCalendar />}
                        allowClear={false}
                        name="startDate"
                        value={values.startDate ? moment(values.startDate) : null}
                        onChange={($moment) => {
                          const stayDurationValue = getDuration({
                            checkout: values.endDate,
                            checkin: $moment.format(),
                          });
                          setFieldValue('startDate', $moment.format());
                          setFieldValue('stayDuration', stayDurationValue < 1 ? '' : stayDurationValue);
                        }}
                        style={{
                          ...BOOKING_DATEPICKER_STYLE,
                          borderColor: `${errors?.startDate ? '#d32452' : ''}`,
                        }}
                        disabledDate={(current) => setDisableStartDates(current, offerId, values)}
                      />
                      {errors?.startDate && <Error error={errors.startDate} />}
                    </InputWrapper>

                    <InputWrapper containerClasses="col-12 col-md-6 col-lg-4">
                      <Label>Check-Out date</Label>
                      <DatePicker
                        size="large"
                        className="date-picked-input"
                        format={DEFAULT_FORMAT}
                        placeholder="End date"
                        suffixIcon={<IconCalendar />}
                        allowClear={false}
                        name="endDate"
                        value={values.endDate ? moment(values.endDate) : null}
                        onChange={($moment) => {
                          const stayDurationValue = getDuration({
                            checkout: $moment.format(),
                            checkin: values.startDate,
                          });
                          setFieldValue('endDate', $moment.format());
                          setFieldValue('stayDuration', stayDurationValue < 1 ? '' : stayDurationValue);
                        }}
                        style={{
                          ...BOOKING_DATEPICKER_STYLE,
                          borderColor: `${errors?.endDate ? '#d32452' : ''}`,
                        }}
                        disabledDate={(current) => checkToDisableOfferEndDates(
                          current,
                          offerId,
                          checkin,
                        )}
                      />
                      {errors?.endDate && <Error error={errors.endDate} />}
                    </InputWrapper>

                    <InputWrapper containerClasses="col-12 col-md-6 col-lg-4">
                      <Label>Total length of stay</Label>
                      <Input
                        readOnly
                        type="text"
                        name="stayDuration"
                        placeholder="# of nights"
                      />
                    </InputWrapper>

                    <InputWrapper containerClasses="col-12 col-md-6 col-lg-4">
                      <Label>Number of guests</Label>
                      <div className={`${styles.fake}`}>
                        {displayGuests(values.adultGuests, values.kidGuests)}
                      </div>
                    </InputWrapper>

                    <InputWrapper containerClasses="col-12 col-md-6 col-lg-4">
                      <Label>Arrival time</Label>
                      <TimePicker
                        size="large"
                        className="date-picked-input"
                        showNow={false}
                        format={ROOM_SERVICE_TIME_FORMAT}
                        name="arrivalTime"
                        onChange={(_moment, value) => setFieldValue('arrivalTime', value)}
                        style={{
                          ...BOOKING_DATEPICKER_STYLE,
                          padding: '11px 15px',
                          borderColor: `${errors?.arrivalTime ? '#d32452' : ''}`,
                        }}
                        minuteStep={BOOKING_MINUTE_STEP}
                      />
                      {errors?.arrivalTime ? (
                        <div className="error-input-msg">{errors.arrivalTime}</div>
                      ) : null}
                    </InputWrapper>
                  </div>
                </div>

                <div className={styles.section}>
                  <h1 className={styles.title}>Main Guest info</h1>
                  <hr className={styles.divider} />
                  <div className="row">
                    <InputWrapper containerClasses="col-12 col-md-6 col-lg-4">
                      <Label>First name</Label>
                      <Input
                        maxLength={USER_PROFILE_TEXT_FIELD_MAX}
                        type="text"
                        name="firstName"
                        placeholder="First name"
                      />
                    </InputWrapper>
                    <InputWrapper containerClasses="col-12 col-md-6 col-lg-4">
                      <Label>Last name</Label>
                      <Input
                        maxLength={USER_PROFILE_TEXT_FIELD_MAX}
                        type="text"
                        name="lastName"
                        placeholder="Last name"
                      />
                    </InputWrapper>
                    <InputWrapper containerClasses="col-12 col-md-6 col-lg-4">
                      <Label>Title</Label>
                      <CustomSelect
                        name={titleSelect.name}
                        placeholder={titleSelect.placeholder}
                        options={titleSelect.options}
                      />
                    </InputWrapper>

                    <InputWrapper containerClasses="col-12 col-md-6 col-lg-4">
                      <Label>Mobile phone</Label>
                      <CustomPhoneInput
                        name="phone"
                      />
                    </InputWrapper>
                    <InputWrapper containerClasses="col-12 col-md-6 col-lg-4">
                      <Label>Email</Label>
                      <Input
                        type="email"
                        name="email"
                        placeholder="Email"
                      />
                    </InputWrapper>
                  </div>
                  <InputWrapper containerClasses={styles.comment}>
                    <Label>Comment</Label>
                    <Input
                      type="text"
                      name="comment"
                      placeholder="Enter comment"
                    />
                  </InputWrapper>
                </div>

                <div className={styles.section}>
                  <h1 className={styles.title}>summary</h1>
                  <hr className={styles.divider} />
                  <h2 className={styles.subtitle}>Offer info</h2>
                  {/* form or form section here */}
                  {offer && (
                    <SearchResultOffer
                      {...offer}
                      hideHeader
                      pricePerNight={subTotalPrice / lengthOfStay}
                      taxPrice={taxPrice}
                      totalPrice={subTotalPrice + taxPrice}
                      hideFooter
                      wrapperClassName={styles.offer}
                      withHeader={false}
                      displayedFeatures={DISPLAYED_BOOKING_FEATURES}
                      descriptionClassName="row col-lg-9"
                      currencyCode={currency}
                    />
                  )}
                  <hr className={styles.dividerMobile} />
                  <div className={`${styles.cancellationWrapper} flex flex-wrap`}>
                    <p className={styles.cancellation}>
                      Cancellation penalty:
                      <span>{PENALTY_TYPES[offer?.cancellationPenaltyType]}</span>
                    </p>
                    <p className={styles.cancellation}>
                      Cancellation deadline:
                      <span>
                        {offer?.cancellationDeadlineInHours
                          ? `${offer?.cancellationDeadlineInHours} hours`
                          : AFTER_BOOKING}
                      </span>
                    </p>
                    {(isFixed || isPercentage) && (
                      <p className={styles.cancellation}>
                        {`${isPercentage ? 'Percentage' : 'Amount'} of penalty:`}
                        <span>
                          {`${isFixed
                            ? formatCurrency(currency, offer?.amountOfPenalty)
                            : offer?.percentageOfPenalty}${isPercentage ? TAX_TYPES_DETAILS_PAGE.PERCENTAGE : ''}`}
                        </span>
                      </p>
                    )}
                  </div>
                </div>

                <hr className={styles.divider} />

                {!!termsAndCondition && (
                  <div>
                    <div className={styles.subtitle}>
                      Terms &amp; Conditions
                    </div>

                    <div className={styles.terms}>
                      {termsAndCondition?.description}
                    </div>
                  </div>
                )}

                <div>
                  {(currency && lengthOfStay) && (
                    <p className={styles.price}>
                      Per night:
                      <span>{formatCurrency(currency, subTotalPrice / lengthOfStay)}</span>
                    </p>
                  )}
                </div>

                <div className="flex flex-between flex-v-center flex-wrap">
                  {!!taxPrice && (
                    <p className={styles.price}>
                      Tax price:
                      <span>{formatCurrency(currency, taxPrice)}</span>
                    </p>
                  )}
                  <p className={styles.price}>
                    Total price for offer:
                    <span>
                      {formatCurrency(currency, (subTotalPrice + taxPrice))}
                    </span>
                  </p>
                  <button
                    disabled={isLoading}
                    type="submit"
                    className={`${styles.button} btn btn_common btn-uppercased t-600`}
                  >
                    Go to Checkout
                  </button>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
};

export default CreateBooking;
