export const PENALTY_TYPE_KEY = 'cancellationPenaltyType';
export const CANCELLATION_DEFAULT_SELECTED_OPTION = 'NON_REFUNDABLE';
export const CANCELLATION_DEADLINE_DEFAULT_SELECTED_OPTION = 48;
export const FIXED_AMOUNT = 'FIXED_AMOUNT';
export const PERCENTAGE = 'PERCENTAGE';
export const ONE_NIGHT = 'ONE_NIGHT';
export const AFTER_BOOKING = 'After booking';
export const EDIT_POLICY_CHANGEABLE = 'editPolicyChanges';
export const PAYMENT_TERMS_KEY = 'paymentTermsType';
export const TIME_OF_BOOKING = 'AT_TIME_OF_BOOKING';
export const BEFORE_CHEKIN_DATE = 'BEFORE_CHECKIN_DATE';
export const PAYMENT_AT_THE_PROPERTY = 'PAYMENT_AT_THE_PROPERTY';
export const DAY_BEFORE_CHECKIN_DATE = 55;
export const PAYMENTS_DEAD_LINE = 'paymentDeadlineInDays';

export const PRICE_TYPES = Object.seal({
  PER_NIGHT: '/ night',
  ENTIRE_STAY: '',
});

export const PRICE_TYPES_OPTIONS = [
  {
    label: 'Per night',
    value: 'PER_NIGHT',
  },
  {
    label: 'Entire stay',
    value: 'ENTIRE_STAY',
  },
];

export const CANCELLATION_OPTIONS = [
  {
    label: 'After booking',
    value: 0,
  },
  {
    label: '72h',
    value: 72,
  },
  {
    label: '48h',
    value: 48,
  },
  {
    label: '24h',
    value: 24,
  },
];

export const TAX_OPTIONS = [
  {
    label: 'GST',
    value: 'GST',
  },
  {
    label: 'VAT',
    value: 'VAT',
  },
  {
    label: 'Service Tax',
    value: 'Service Tax',
  },
];

export const GUEST_PRICE_TYPES = Object.seal({
  PER_NIGHT: '/ night',
  ENTIRE_STAY: 'package',
});

export const TAX_TYPES = Object.seal({
  FIXED: '',
  PERCENTAGE: '%',
});

export const TAX_TYPES_DETAILS_PAGE = Object.seal({
  FIXED: '$',
  PERCENTAGE: '% of total price',
});

export const OFFER_TAX_TYPES = [
  {
    label: 'Fixed',
    value: 'FIXED',
  },
  {
    label: '%',
    value: 'PERCENTAGE',
  },
];

export const PENALTY_TYPES = Object.seal({
  NON_REFUNDABLE: 'Non-refundable',
  ONE_NIGHT: '1 night',
  FIXED_AMOUNT: 'Exact value',
  PERCENTAGE: 'Percentage of the transaction amount',
});
