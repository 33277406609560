import React from 'react';
import CustomerSettingsWrapper from '../../../components/Users/CustomerSettingsWrapper/CustomerSettingsWrapper';
import PersonalInfo from '../../../components/Users/PersonalInfo/PersonalInfo';

const EditCustomerPersonal = () => (
  <CustomerSettingsWrapper>
    <PersonalInfo titleText="Profile info" submitBtnTitle="Save changes" showCancelBtn />
  </CustomerSettingsWrapper>
);

export default EditCustomerPersonal;
