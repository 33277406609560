import React, { useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import { Link, navigate } from 'gatsby';

import StripePaymentModal from '../../../components/StripePaymentModal/StripePaymentModal';
import Modal from '../../../components/UI/Modal/Modal';
import Button from '../../../components/UI/Button/Button';
import BookingsService from '../../../services/bookings.service';
import {
  formatCurrency, toggleBodyStylesForModal, objectIsNotEmpty,
} from '../../../helpers/helper-methods';
import IconPDF from '../../../assets/img/icons/ic_pdf.svg';
import IconSpinner from '../../../assets/img/icons/ic-spinner-colorable.svg';
import { DEFAULT_FORMAT } from '../../../constans/formats';
import { PAYMENT_STATUS_MAP, PAYMENT_STATUS, PAYMENT_TERMS } from '../../../constans/payments';
import {
  AFTER_BOOKING, FIXED_AMOUNT, PENALTY_TYPES, PERCENTAGE, TAX_TYPES_DETAILS_PAGE,
} from '../../../constans/price-and-taxes';
import useDownloadBookingPdf from '../../../hooks/useDownloadBookingPdf';
import { CONTACTS_LINK, CUSTOMER_BOOKINGS } from '../../../constans/links';
import { BOOKINGS_STATUSES, BOOKING_PAYMENT_STATUSES } from '../../../constans/bookings';
import Spinner from '../../../components/UI/Spinner/Spinner';

import styles from './BookingDetails.module.scss';
import { EMPTY } from '../../../constans/texts/texts';

export const Data = ({
  title, value, truncate = true, className,
}) => (
  <div className={`${styles.info} ${className} flex flex-col`}>
    <p className={styles.infoTitle}>
      {title}
    </p>
    <div title={truncate ? value : ''} className={`${styles.infoValue} ${truncate && 'text-truncate'}`}>
      {value}
    </div>
  </div>
);

const BookingDetails = ({ bookingId }) => {
  const bookingsService = useMemo(() => BookingsService(), []);
  const [booking, setBooking] = useState({});
  const [stripePaymentModal, setStripePaymentModal] = useState(false);
  const [displayCancelModal, setDisplayCancelModal] = useState(false);
  const [disableCancelConfirmBtn, setDisableCancelConfirmBtn] = useState(false);
  const [loading, setLoading] = useState(false);
  const displayGuests = (adults, kids) => (`${adults}A ${kids ? `+ ${kids}K` : ''}`);

  const toggleCancelModal = () => {
    toggleBodyStylesForModal();
    setDisplayCancelModal((prevState) => !prevState);
  };

  const getBookingDetails = async () => {
    const { data } = await bookingsService.getBookingDetails(bookingId);

    if (data) {
      setBooking({
        ...data,
        pastBooking: data.endDate < moment().format()
          || data.status === BOOKINGS_STATUSES.CANCELED,
      });
    }
    setLoading(false);
  };

  const cancelBookingHandler = () => {
    setDisableCancelConfirmBtn(true);
    bookingsService.cancelBooking(bookingId)
      .then(() => {
        getBookingDetails();
        toggleCancelModal();
        setDisableCancelConfirmBtn(false);
      })
      .catch(() => {
        toggleCancelModal();
        setDisableCancelConfirmBtn(false);
      });
  };

  const {
    firstName,
    lastName,
    arrivalTime,
    numberOfAdults,
    numberOfKids,
    comment,
    prefix,
    phone,
    email,
    startDate,
    endDate,
    lengthOfStay,
    totalPrice,
    subTotalPrice,
    taxPrice,
    user = {},
    offer = {},
  } = booking;

  const {
    room,
    property,
    features,
    cancellationPenaltyType,
    cancellationDeadlineInHours,
    termsAndCondition = {},
    amountOfPenalty,
    percentageOfPenalty,
  } = offer;
  const isFixed = cancellationPenaltyType === FIXED_AMOUNT;
  const isPercentage = cancellationPenaltyType === PERCENTAGE;

  const { downloadBookingPdf, downloadPdfLoading } = useDownloadBookingPdf();
  const toggleStripePaymentModal = () => {
    toggleBodyStylesForModal();
    setStripePaymentModal((prevState) => !prevState);
  };

  const stripeSubmitCallback = () => {
    getBookingDetails();
    toggleStripePaymentModal();
  };

  useEffect(() => {
    if (bookingId) {
      setLoading(true);
      getBookingDetails();
    } else {
      navigate(CUSTOMER_BOOKINGS);
    }
  }, [bookingId]);

  return objectIsNotEmpty(booking) ? (
    <div className={`container ${styles.page}`}>
      <Modal
        modalClasses={styles.cancelWindow}
        show={displayCancelModal}
        displayToggle={toggleCancelModal}
      >
        <div className="h5-title">
          Cancellation #
          {offer?.code}
        </div>
        <p className={`${styles.cancelWindowSubtitle} t-500`}>
          Are you sure you want to cancel this Booking?
        </p>
        <div className={`${styles.cancelWindowFooter} flex flex-h-center`}>
          <Button
            onClick={toggleCancelModal}
            classes={`${styles.cancelWindowBtn} btn btn_room-md btn-uppercased btn_bordered btn_padded btn_brs-28 t-700`}
          >
            <span className="btn_text">
              cancel
            </span>
          </Button>
          <Button
            disabled={disableCancelConfirmBtn}
            onClick={cancelBookingHandler}
            classes={`${styles.cancelWindowBtn} btn btn_room-md btn-uppercased btn_padded btn_danger-bg btn_brs-28 t-600`}
          >
            <span className="btn_text">
              confirm
            </span>
          </Button>
        </div>
      </Modal>
      <StripePaymentModal
        bookingId={bookingId}
        displayToggle={toggleStripePaymentModal}
        show={stripePaymentModal}
        stripeSubmitCallback={stripeSubmitCallback}
      />
      {loading
        ? <Spinner spinnerClasses="spinner-primary" />
        : objectIsNotEmpty(booking) && (
          <div className={`row ${styles.mt40}`}>
            <div className="col-12 col-lg-8">
              <div className={styles.card}>
                <div className="flex flex-wrap flex-h-start">
                  <h4 className={styles.cardHeader4}>
                    Booking #
                    {offer?.code}
                  </h4>
                  {booking.status === BOOKINGS_STATUSES.CANCELED ? (
                    <div className={`${styles.chip} status-item blocked`}>
                      Cancelled
                    </div>
                  ) : (
                    <div className={`${styles.chip} ${PAYMENT_STATUS_MAP[booking?.paymentStatus]?.background} status-item`}>
                      {PAYMENT_STATUS_MAP[booking?.paymentStatus]?.text}
                    </div>
                  )}
                  <button
                    className="ml-auto btn"
                    type="button"
                    disabled={downloadPdfLoading[booking.id]}
                    onClick={() => downloadBookingPdf(booking)}
                  >
                    {(downloadPdfLoading[booking.id]) ? (
                      <IconSpinner className={styles.loadingIcon} />
                    ) : (
                      <IconPDF />
                    )}
                  </button>
                </div>
                <hr className={styles.cardDivider} />
                <h6 className={`${styles.cardHeader6} ${styles.mb30}`}>booked by</h6>
                <div className="row">
                  <Data className="col-12 col-md-6 col-lg" title="Full name" value={`${user?.firstName} ${user?.lastName}`} />
                  <Data className="col-12 col-md-6 col-lg-2" title="Title" value={user?.title} />
                  <Data className="col-12 col-md-6 col-lg-3" title="Phone number" value={user?.phone} />
                  <Data className="col-12 col-md-6 col-lg" title="Email address" value={user?.email} />
                </div>
                <hr className={`${styles.cardDivider} ${styles.mt10}`} />

                <h6 className={`${styles.cardHeader6} ${styles.mb30}`}>
                  Main guest details
                </h6>
                <div className="row">
                  <Data className="col-12 col-md-6 col-lg" title="Full name" value={`${firstName} ${lastName}`} />
                  <Data className="col-12 col-md-6 col-lg-2" title="Title" value={prefix || EMPTY} />
                  <Data className="col-12 col-md-6 col-lg-3" title="Phone number" value={phone} />
                  <Data className="col-12 col-md-6 col-lg" title="Email address" value={email} />
                </div>
                <hr className={`${styles.cardDivider} ${styles.mt10}`} />

                <h6 className={`${styles.cardHeader6} ${styles.mb30}`}>
                  Booking details
                </h6>
                <div className="row">
                  <Data
                    className="col-12 col-md-6 col-lg-4"
                    title="Check-In date"
                    value={moment(startDate).format(DEFAULT_FORMAT)}
                  />
                  <Data
                    className="col-12 col-md-6 col-lg-4"
                    title="Check-Out date"
                    value={moment(endDate).format(DEFAULT_FORMAT)}
                  />
                  <Data
                    className="col-12 col-md-6 col-lg-4"
                    title="Total length of stay"
                    value={lengthOfStay > 1 ? `${lengthOfStay} days` : `${lengthOfStay} day`}
                  />
                  <Data
                    className="col-12 col-md-6 col-lg-4"
                    title="Number of guests"
                    value={displayGuests(numberOfAdults, numberOfKids)}
                  />
                  <Data className="col-12 col-md-6 col-lg" title="Arrival time" value={arrivalTime} />
                </div>
                <div className="row">
                  <Data
                    className="col"
                    title="Offer information"
                    value={(
                      <div className="flex flex-col">
                        <div className={styles.offer}>
                          {`${room?.title}  -  ${property?.title}`}
                        </div>
                        <div className="flex flex-wrap">
                          {features?.map(({ title, id: featureId }) => (
                            <div
                              title={title}
                              key={featureId}
                              className={styles.feature}
                            >
                              {title}
                            </div>
                          ))}
                        </div>
                      </div>
                    )}
                  />
                </div>
                <Data truncate={false} title="Comment" value={comment} />
                <Data
                  title="Cancellation policy"
                  value={(
                    <div className={styles.cancellation}>
                      <p>
                        Cancellation penalty:
                        <span>{PENALTY_TYPES[cancellationPenaltyType]}</span>
                      </p>
                      <p>
                        Cancellation deadline:
                        <span>
                          {cancellationDeadlineInHours
                            ? `${cancellationDeadlineInHours} hours`
                            : AFTER_BOOKING}
                        </span>
                      </p>
                    </div>
                  )}
                />
                {(isFixed || isPercentage) && (
                  <Data
                    title={`${isPercentage ? 'Percentage' : 'Amount'} of penalty`}
                    value={`${isFixed
                      ? formatCurrency(offer?.currency?.code || 'USD', amountOfPenalty)
                      : percentageOfPenalty}${isPercentage ? TAX_TYPES_DETAILS_PAGE.PERCENTAGE : ''}
                    `}
                  />
                )}
                {termsAndCondition?.title && termsAndCondition?.description ? (
                  <Data
                    className="mb-0"
                    truncate={false}
                    title="Terms & Conditions"
                    value={termsAndCondition?.description}
                  />
                ) : null}
              </div>
            </div>
            {/* right side */}
            <div className="col">
              {booking?.status !== BOOKINGS_STATUSES.CANCELED
                && !booking?.pastBooking ? (
                <div className={styles.card}>
                  <div className={styles.cardButtons}>
                    <Link
                      to="edit"
                      className="btn btn_link btn_block btn_common text-center btn-uppercased t-500"
                    >
                      Edit booking
                    </Link>
                    <button
                      onClick={toggleCancelModal}
                      type="button"
                      className="btn btn_link btn_common btn_danger-bg btn-uppercased t-500"
                    >
                      Cancel booking
                    </button>
                  </div>
                </div>
              ) : null}
              <div className={styles.card}>
                <h6 className={`${styles.cardHeader6} ${styles.mb30}`}>
                  Payment details
                </h6>
                {booking?.paymentStatus === BOOKING_PAYMENT_STATUSES.PAID && (
                  <>
                    {booking?.payment?.paymentMethod && (
                      <Data
                        title="Payment method"
                        value={`${booking?.payment?.card?.brand} ${booking?.payment?.paymentMethod}`}
                      />
                    )}
                    {booking?.payment?.card && (
                      <>
                        <Data title="Card data" value={`**** ${booking?.payment?.card?.last4}`} />
                        <Data title="Payment date" value={moment(booking?.payment?.card?.createdAt).format(DEFAULT_FORMAT)} />
                      </>
                    )}
                  </>
                )}
                {offer?.paymentTermsType === PAYMENT_TERMS.AT_TIME_OF_BOOKING ? (
                  <Data title="Due date" value={moment(booking?.createdAt).format(DEFAULT_FORMAT)} />
                ) : null}
                {offer?.paymentTermsType === PAYMENT_TERMS.BEFORE_CHECKIN_DATE ? (
                  <Data
                    title="Due date"
                    value={moment(booking?.startDate)
                      .subtract(offer?.paymentDeadlineInDays, 'days')
                      .format(DEFAULT_FORMAT)}
                  />
                ) : null}
                {offer?.paymentTermsType === PAYMENT_TERMS.PAYMENT_AT_THE_PROPERTY ? (
                  <Data title="Due date" value={moment(booking?.startDate).format(DEFAULT_FORMAT)} />
                ) : null}
                <div className="flex flex-col">
                  {booking?.currency?.code ? (
                    <>
                      <hr className={`${styles.cardDivider} ${styles.mx30}`} />
                      <Data title="Price before tax" value={formatCurrency(booking?.currency?.code, subTotalPrice)} />
                      <Data title="Tax" value={formatCurrency(booking?.currency?.code, taxPrice)} />
                      <Data title="Total price" value={formatCurrency(booking?.currency?.code, totalPrice)} />
                    </>
                  ) : null}
                  {!booking?.pastBooking
                    && booking?.status !== BOOKINGS_STATUSES.CANCELED
                    && booking?.paymentStatus !== PAYMENT_STATUS.PAID
                    && offer?.paymentTermsType !== PAYMENT_TERMS.PAYMENT_AT_THE_PROPERTY ? (
                    <button
                      onClick={toggleStripePaymentModal}
                      type="button"
                      className="btn btn_common btn-uppercased t-500"
                    >
                      make payment
                    </button>
                  ) : null}
                </div>
                <hr className={`${styles.cardDivider} ${styles.mx30}`} />
                {/* if not payed end */}
                <Link to={CONTACTS_LINK} className={`${styles.contactButton} text-center btn`}>
                  Contact Koralgo about this booking
                </Link>
              </div>
            </div>
          </div>
        )}
    </div>
  ) : <Spinner spinnerClasses="spinner-primary" />;
};

export default BookingDetails;
