import React from 'react';
import Modal from '../../UI/Modal/Modal';
import styles from './AmenitiesModal.module.scss';

const AmenitiesModal = ({
  showModal,
  toggleModal,
  amenities,
  capacitiesObj,
  surface,
  bedTypesArr,
}) => (
  <Modal
    modalWrapperStyles={styles.modalWrapper}
    displayToggle={toggleModal}
    show={showModal}
    modalClasses={`${styles.modalContentWrapper} modal-content`}
    closeStyles={styles.modalCloseBtn}
  >
    <div className={`${styles.title} t-700`}>
      Room Information
    </div>
    <div className={styles.modalInnerWrapper}>
      <ul>
        {!!surface && (
          <li
            className={`${styles.amenityItem} t-500`}
          >
            Surface
            <span className={styles.delimiter}>-</span>
            {surface}
            m2
          </li>
        )}
        {capacitiesObj && Object.keys(capacitiesObj).map((capacityKey, index) => (
          <li
            key={index}
            className={`${styles.amenityItem} t-500`}
          >
            <span>{capacitiesObj[capacityKey].modalLabel}</span>
            <span className={styles.delimiter}>-</span>
            {capacitiesObj[capacityKey].values.map((capacityValues, innerIndex) => (
              <span
                className={styles.capacityItem}
                key={`sdfd${innerIndex}`}
              >
                {capacityValues.value}
                {capacityValues.prefix}
              </span>
            ))}
          </li>
        ))}
        {bedTypesArr?.length > 0
        &&
          <li
            
            className={`${styles.amenityItem} t-500`}
          >
            {bedTypesArr.map((bedType, index) => (
              <span key={index}>
                <span>{bedType.title} {" "}</span>
                <span>
                  (
                  {bedType.bedWidth}
                  cm)
                </span>
                {(bedTypesArr.length - 1) > index && <span className={styles.delimiter}>
                  {((bedTypesArr[index]?.title === bedTypesArr[index + 1]?.title) && (bedTypesArr[index]?.bedWidth === bedTypesArr[index + 1]?.bedWidth)) 
                    ?  "+"
                    : "OR"
                  }
                  </span>}
              </span>
            ))}
          </li>
        }
        {amenities && amenities.map((amenity, index) => (
          <li
            key={index}
            className={`${styles.amenityItem} t-500`}
          >
            {amenity.title}
          </li>
        ))}
      </ul>
    </div>
  </Modal>
);

export default AmenitiesModal;
