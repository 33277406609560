import React from 'react';
import toastr from 'toastr';
import http from '../../../services/http.service';
import CustomerSettingsWrapper from '../../../components/Users/CustomerSettingsWrapper/CustomerSettingsWrapper';
import ChangePasswordForm from '../../../components/ChangePasswordForm/ChangePasswordForm';
import styles from './EditCustomerPassword.module.scss';
import { API } from '../../../constans/http';
import { SUCCESS_SEND_MSG } from '../../../constans/texts/texts';
import { passwordInitialValues } from '../../../constans/init-values';

const EditCustomerPassword = () => {
  const formSubmitHandler = ({ password, confirmPassword }, { setSubmitting, resetForm }) => {
    http.patch(API.CUSTOMERS_PASSWORD, { newPassword: confirmPassword, password })
      .then(() => {
        setSubmitting(false);
        resetForm(passwordInitialValues);
        toastr.success(SUCCESS_SEND_MSG);
      })
      .catch(() => {
        setSubmitting(false);
      });
  };
  return (
    <CustomerSettingsWrapper>
      <ChangePasswordForm
        showCancelBtn
        contentContainerStyles="customer-setting-content-wrapper"
        formSubmitHandler={formSubmitHandler}
        submitBtnStyles={styles.submitBtn}
      />
    </CustomerSettingsWrapper>
  );
};

export default EditCustomerPassword;
