import React, { useEffect, useMemo, useState } from 'react';
import CustomersBookingsItem from '../CustomersBookingsItem/CustomersBookingsItem';
import BookingsService from '../../../services/bookings.service';
import { CUSTOMER_BOOKINGS_LIMIT, BOOKINGS_STATUSES } from '../../../constans/bookings';
import Pagination from '../../Pagination';
import Spinner from '../../UI/Spinner/Spinner';
import useDownloadBookingPdf from '../../../hooks/useDownloadBookingPdf';

const CustomersBookingsList = ({
  activeIndex, status, showDueDate, placeholderTitleStyles,
}) => {
  const bookingsService = useMemo(() => BookingsService(), []);
  const [pageNumber, setPageNumber] = useState(0);
  const [total, setTotal] = useState(0);
  const [bookings, setBookings] = useState([]);
  const [loading, setLoading] = useState(false);
  const { downloadBookingPdf, downloadPdfLoading } = useDownloadBookingPdf();

  const fetchBookingsData = ({ selected: page }) => {
    setLoading((prevState) => !prevState);
    bookingsService.getBookingsList({
      status,
      limit: CUSTOMER_BOOKINGS_LIMIT,
      offset: page * CUSTOMER_BOOKINGS_LIMIT,
    })
      .then(({ data, pagination: { totalCount } }) => {
        setBookings(data);
        setPageNumber(page);
        setTotal(totalCount);
        setLoading((prevState) => !prevState);
      })
      .catch(() => setLoading((prevState) => !prevState));
  };

  useEffect(() => {
    fetchBookingsData({ selected: 0 });
  }, [activeIndex]);

  return (
    <>
      {!loading ? (
        <>
          {bookings.length > 0 ? (
            <>
              {bookings.map((bookingItem, index) => (
                <CustomersBookingsItem
                  key={`dsgfd${index}${activeIndex}`}
                  bookingData={bookingItem}
                  dueDateHide={showDueDate}
                  downloadPdf={downloadBookingPdf}
                  isPdfLoading={downloadPdfLoading[bookingItem.id]}
                />
              ))}
              <Pagination
                perPage={CUSTOMER_BOOKINGS_LIMIT}
                forcePage={pageNumber}
                total={total}
                callback={fetchBookingsData}
              />
            </>
          ) : (
            <div className={`${placeholderTitleStyles} t-600`}>
              No
              {' '}
              {BOOKINGS_STATUSES.UPCOMING === status ? 'upcoming' : 'past'}
              {' '}
              bookings
            </div>
          )}
        </>
      ) : <Spinner spinnerClasses="spinner-primary" />}
    </>
  );
};

CustomersBookingsList.defaultProps = {
  placeholderTitle: '',
  bookings: [],
};

export default CustomersBookingsList;
