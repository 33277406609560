import { useCallback, useState } from 'react';
import moment from 'moment';

import BookingsService from '../services/bookings.service';
import FileSaverService from '../services/file-saver.service';
import { DEFAULT_FORMAT } from '../constans/formats';

const useDownloadBookingPdf = () => {
  const [downloadPdfLoading, setDownloadPdfLoading] = useState({});

  const downloadBookingPdf = useCallback((booking) => {
    setDownloadPdfLoading({
      ...downloadPdfLoading,
      [booking.id]: true,
    });
    BookingsService()
      .getBookingPdf(booking.id)
      .then((buffer) => {
        const fileName = (() => {
          const propertyTitle = booking
            .offer
            .property
            .title
            .toLowerCase()
            .replace(/\s+/g, '-');
          const createdAt = moment(booking.createdAt).format(DEFAULT_FORMAT);
          return `${propertyTitle}-${createdAt}.pdf`;
        })();
        FileSaverService().saveFile(buffer, fileName);
      })
      .catch((err) => {
        // todo: show toast?
        console.warn(err);
      })
      .finally(() => {
        setDownloadPdfLoading((prevLoading) => ({
          ...prevLoading,
          [booking.id]: false,
        }));
      });
  });

  return { downloadBookingPdf, downloadPdfLoading };
};

export default useDownloadBookingPdf;
