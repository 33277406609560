import React, {
  useEffect, useMemo, useRef, useState,
} from 'react';
import toastr from 'toastr';
import isEqual from 'lodash.isequal';
import { Form, Formik } from 'formik';
import { object, string } from 'yup';
import { Link, navigate } from 'gatsby';
import { DatePicker, TimePicker } from 'antd';
import moment from 'moment';
import SwiperCore, { Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import http from '../../../services/http.service';
import { Data } from '../details/BookingDetails';
import InputWrapper from '../../../components/UI/InputWrapper/InputWrapper';
import Label from '../../../components/UI/Label/Label';
import { Input } from '../../../components/UI/Input/Input';
import CustomSelect from '../../../components/UI/CustomSelect/CustomSelect';
import IconCalendar from '../../../assets/img/icons/ic-calendar-active.svg';
import IconTime from '../../../assets/img/icons/ic-time-default.svg';
import NextPage from '../../../assets/img/icons/ic-chevron-right-light-gray.svg';
import PrevPage from '../../../assets/img/icons/ic-chevron-left-light-gray.svg';
import Error from '../../../components/UI/Error/Error';
import Modal from '../../../components/UI/Modal/Modal';
import Spinner from '../../../components/UI/Spinner/Spinner';
import BookingsService from '../../../services/bookings.service';
import {
  checkToDisableOfferEndDates,
  formatCurrency,
  offerFloatPriceCalcDifference,
  setDisableStartDates,
  toggleBodyStylesForModal,
} from '../../../helpers/helper-methods';
import { bookingBaseValidationSchema } from '../../../constans/validation-schemas';
import {
  USER_FIRST_NAME_REQUIRED,
  USER_LAST_NAME_REQUIRED,
  USER_NAME_INVALID,
} from '../../../constans/error-messages';
import { REGEXP_NOSPACES, USER_PROFILE_TEXT_FIELD_MAX } from '../../../constans/validation';
import { SUCCESS_SEND_MSG } from '../../../constans/texts/texts';
import { API } from '../../../constans/http';
import { DEFAULT_FORMAT, ROOM_SERVICE_TIME_FORMAT } from '../../../constans/formats';
import { BOOKING_DATEPICKER_STYLE, BOOKING_DATEPICKER_ERRORED, BOOKING_MINUTE_STEP } from '../../../constans/bookings';
import { DISPLAYED_SEARCH_RESULT_FEATURES } from '../../../constans/search';
import { titleSelect } from '../../../constans/form-fields';

import styles from './EditBooking.module.scss';

// install Swiper components
SwiperCore.use([Navigation, Pagination]);

const validationSchema = object().shape({
  firstName: string().required(USER_FIRST_NAME_REQUIRED)
    .matches(REGEXP_NOSPACES, USER_NAME_INVALID),
  lastName: string().required(USER_LAST_NAME_REQUIRED)
    .matches(REGEXP_NOSPACES, USER_NAME_INVALID),
}).concat(bookingBaseValidationSchema);

const Offer = ({
  selectButtonClick,
  offerItem,
  index,
}) => (
  <div className={`${styles.offer} flex flex-col`}>
    <div className="flex flex-between flex-v-center">
      <div className={styles.offerPv}>
        #
        {index + 1}
        {' '}
        PV :
        {' '}
        {offerItem.PV ? (offerItem.PV).toFixed(2) : null}
      </div>
      <div className={styles.offerPrice}>
        {formatCurrency(offerItem?.currencyCode, offerItem?.totalPrice)}
      </div>
    </div>
    <div className={styles.offerTitle}>
      {offerItem.title}
    </div>
    <p className={styles.offerRoom}>
      Room type:
      <span>
        {offerItem.room.title}
      </span>
    </p>
    {offerItem?.features?.length > 0 ? (
      <div className={styles.offerFeatureWrapper}>
        {offerItem.features
          .slice(0, DISPLAYED_SEARCH_RESULT_FEATURES)
          .map(({ title: featureTitle }, innerIndex) => (
            <div key={`dsfdf${innerIndex}`} className={styles.offerFeature}>
              <div className={styles.dot} />
              {featureTitle}
            </div>
          ))}
        {offerItem?.features?.length - DISPLAYED_SEARCH_RESULT_FEATURES > 0 ? (
          <div className={styles.offerFeatureRest}>
            {`+ ${offerItem.features.length - DISPLAYED_SEARCH_RESULT_FEATURES} ${offerItem.features.length - DISPLAYED_SEARCH_RESULT_FEATURES > 1 ? 'features' : 'feature'}`}
          </div>
        ) : null}
      </div>
    ) : null}
    <button
      onClick={selectButtonClick}
      type="button"
      className={`${styles.offerButton} btn btn_inverted btn-uppercased t-600`}
    >
      select offer
    </button>
  </div>
);

/* todo: remove mok */
const EditBooking = ({ bookingId }) => {
  const bookingDetailsLink = `/customers/bookings/${bookingId}`;
  const prevRef = useRef(null);
  const nextRef = useRef(null);
  const bookingsService = useMemo(() => BookingsService(), []);
  const [bookingData, setBookingData] = useState(null);
  const [roomTypes, setRoomTypes] = useState([]);
  const [initialValues, setInitialValues] = useState({
    offerId: 0,
    arrivalTime: '',
    nickName: '',
    title: '',
    firstName: '',
    lastName: '',
    email: '',
    comment: '',
    startDate: null,
    endDate: null,
    roomType: '',
  });
  const [formSubmitData, setFormSubmitData] = useState(null);
  const [editModalData, setEditModalData] = useState(null);
  const [suitableOffersData, setSuitableOffersData] = useState([]);
  const [showEditModal, setShowEditModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [editModalSubmitLoading, setEditModalSubmitLoading] = useState(false);

  const toggleEditModal = () => {
    toggleBodyStylesForModal();
    setShowEditModal(!showEditModal);
  };

  useEffect(() => {
    //   fetch booking data
    bookingsService.getBookingDetails(bookingId)
      .then(({ data }) => {
        const bookingDetails = {
          offerId: data?.offer?.id || null,
          offerTotalPrice: data?.totalPrice || 0,
          fullName: `${data?.firstName || ''} ${data?.lastName || ''}`,
          startDate: data?.startDate ? data.startDate : '',
          endDate: data?.endDate ? moment(data.endDate).format(DEFAULT_FORMAT) : '',
          arrivalTime: data?.arrivalTime || '',
          roomType: data?.offer?.room?.title || '',
          propertyTitle: data?.offer?.property?.title || '',
          offerFeatures: data?.offer?.features || [],
          currency: data?.currency?.code,
        };
        const initValuesData = {
          offerId: data?.offer?.id || null,
          editPolicy: data?.offer?.editPolicy
            || { dates: true, roomTypes: true, mainGuestName: true },
          arrivalTime: data?.arrivalTime || '',
          title: data?.prefix || '',
          firstName: data?.firstName || '',
          lastName: data?.lastName || '',
          email: data?.email || '',
          comment: data?.comment || '',
          nickName: data?.nickName || '',
          roomType: data?.offer?.room?.id || '',
          startDate: data?.startDate ? data.startDate : null,
          endDate: data?.endDate ? data.endDate : null,
        };

        setBookingData(bookingDetails);
        setInitialValues(initValuesData);
        return data?.offer?.property?.id;
      })
      .then((propertyId) => http.get('/rooms', { params: { propertyId } }))
      .then(({ data }) => {
        const roomTypesArr = data.map((roomItem) => ({
          label: roomItem.title,
          value: roomItem.id,
        }));
        setRoomTypes(roomTypesArr);
      });
  }, []);

  const editBookingHandler = (bookId, { title, ...values }) => bookingsService
    .editBooking(bookId, { ...values, prefix: title })
    .then(() => {
      toastr.success(SUCCESS_SEND_MSG);
    });

  // eslint-disable-next-line no-unused-vars
  const formSubmitHandler = ({ roomType, editPolicy, ...values }, { setSubmitting, ...rest }) => {
    const offersSearchData = {
      roomType,
      startDate: values.startDate,
      endDate: values.endDate,
    };
    const initialOffersSearchData = {
      roomType: initialValues.roomType,
      startDate: initialValues.startDate,
      endDate: initialValues.endDate,
    };
    // Check for changes between initialValues and values on submit moment (CheckIn || CheckOut)

    if (isEqual(offersSearchData, initialOffersSearchData)) {
      editBookingHandler(bookingId, values)
        .then(() => setSubmitting(false))
        .catch(() => setSubmitting(false));
    } else {
      setLoading(true);
      http.get(API.SEARCH_OFFERS_SUITABLE, {
        params: {
          bookingId,
          roomId: roomType,
          checkIn: values.startDate,
          checkOut: values.endDate,
        },
      })
        .then(({ data }) => {
          setFormSubmitData(values);
          setSuitableOffersData(data);
          setLoading(false);
        })
        .catch(() => setLoading(false));
    }
  };

  const suitableOfferSelectHandler = (offerItem) => {
    const editDataModel = {
      newOfferId: offerItem.id,
      priceDifference: offerFloatPriceCalcDifference(offerItem.totalPrice,
        bookingData.offerTotalPrice),
      newOfferPrice: offerItem.totalPrice,
      prevOfferPrice: bookingData.offerTotalPrice,
      currency: bookingData.currency,
    };
    toggleEditModal();
    setEditModalData(editDataModel);
  };

  const editModalSubmitHandler = () => {
    setEditModalSubmitLoading(true);
    editBookingHandler(bookingId, { ...formSubmitData, offerId: editModalData.newOfferId })
      .then(() => {
        toggleEditModal();
        setEditModalSubmitLoading(false);
        navigate(bookingDetailsLink);
      })
      .catch(() => {
        toggleEditModal();
        setEditModalSubmitLoading(false);
      });
  };

  return (
    <div className={`container ${styles.page}`}>
      {bookingData ? (
        <div className={styles.card}>
          {editModalData ? (
            <Modal
              modalClasses={`${styles.editModal} center-content-vertical-sm-desktop`}
              show={showEditModal}
              displayToggle={toggleEditModal}
            >
              <div className={`${styles.editModalTitle} h5-title`}>
                Price difference is
                {' '}
                {formatCurrency(editModalData.currency, editModalData.priceDifference)}
              </div>
              <div className={`${styles.editModalSubtitle} t-500`}>
                New Offer Price:
                {' '}
                {formatCurrency(editModalData.currency, editModalData.newOfferPrice)}
              </div>
              <div className={`${styles.editModalSubtitle} t-500`}>
                Previous Offer Price:
                {' '}
                {formatCurrency(editModalData.currency, editModalData.prevOfferPrice)}
              </div>
              <div className={`${styles.editModalConfirmMsg} t-500`}>
                Do you wish to confirm and edit your existing booking?
              </div>
              <div className={`${styles.editModalFooter} flex flex-wrap flex-h-center`}>
                <button
                  onClick={toggleEditModal}
                  type="button"
                  className={`${styles.editModalFooterBtn} btn btn_bordered btn_padded t-700 btn_brs-28 btn-uppercased`}
                >
                  no
                </button>
                <button
                  disabled={editModalSubmitLoading}
                  onClick={editModalSubmitHandler}
                  type="button"
                  className={`${styles.editModalFooterBtn} btn btn_common btn-uppercased t-700`}
                >
                  Yes
                </button>
              </div>
            </Modal>
          ) : null}
          <div>
            <h4 className={styles.cardHeader6}>
              Current booking details
            </h4>
            <div className="flex flex-wrap">
              {bookingData?.fullName ? (
                <Data title="Full name" value={bookingData.fullName} className={styles.data} />
              ) : null}
              {bookingData.startDate ? (
                <Data
                  title="check-in date"
                  value={moment(bookingData.startDate).format(DEFAULT_FORMAT)}
                  className={styles.data}
                />
              ) : null}
              {bookingData.endDate ? (
                <Data
                  title="check-out date"
                  value={bookingData.endDate}
                  className={styles.data}
                />
              ) : null}
              {bookingData.arrivalTime ? (
                <Data title="Arrival time" value={bookingData.arrivalTime} className={styles.data} />
              ) : null}
            </div>
            <Data
              title="Offer information"
              value={(
                <div className="flex flex-col">
                  <div className={styles.information}>
                    {bookingData.roomType}
                    {' '}
                    -
                    {' '}
                    {bookingData.propertyTitle}
                  </div>
                  {bookingData?.offerFeatures?.length > 0 ? (
                    <div className="flex flex-wrap">
                      {bookingData?.offerFeatures.map((offerFeature, index) => (
                        <div
                          title={offerFeature.title}
                          key={`sdfdf${index}`}
                          className={styles.feature}
                        >
                          {offerFeature.title}
                        </div>
                      ))}
                    </div>
                  ) : null}
                </div>
              )}
            />
          </div>

          <hr className={styles.cardDivider} />

          <div>
            <h4 className={styles.cardHeader6}>
              Amended booking details
            </h4>
            <Formik
              onSubmit={formSubmitHandler}
              enableReinitialize
              validateOnBlur={false}
              validateOnChange={false}
              initialValues={initialValues}
              validationSchema={validationSchema}
            >
              {({
                values, setFieldValue, errors, dirty,
              }) => (
                <Form>
                  <div className="row">
                    <InputWrapper containerClasses="col-12 col-md-6 col-lg-4">
                      <Label>First name</Label>
                      <Input
                        maxLength={USER_PROFILE_TEXT_FIELD_MAX}
                        disabled={!values?.editPolicy?.mainGuestName}
                        type="text"
                        name="firstName"
                        placeholder="First name"
                      />
                    </InputWrapper>

                    <InputWrapper containerClasses="col-12 col-md-6 col-lg-4">
                      <Label>Last name</Label>
                      <Input
                        maxLength={USER_PROFILE_TEXT_FIELD_MAX}
                        disabled={!values?.editPolicy?.mainGuestName}
                        type="text"
                        name="lastName"
                        placeholder="Last name"
                      />
                    </InputWrapper>

                    <InputWrapper containerClasses="col-12 col-md-6 col-lg-4">
                      <Label>Title</Label>
                      <CustomSelect
                        disabled={!values?.editPolicy?.mainGuestName}
                        name="title"
                        placeholder={titleSelect.placeholder}
                        options={titleSelect.options}
                      />
                    </InputWrapper>

                    <InputWrapper containerClasses="col-12 col-md-6 col-lg-4">
                      <Label>Arrival time</Label>
                      <TimePicker
                        className="date-picked-input"
                        disabled={!values?.editPolicy?.mainGuestName}
                        name="arrivalTime"
                        placeholder="Select time"
                        size="large"
                        allowClear={false}
                        showNow={false}
                        onChange={(_moment, value) => setFieldValue('arrivalTime', value)}
                        value={values?.arrivalTime
                          ? moment(values.arrivalTime, ROOM_SERVICE_TIME_FORMAT) : null}
                        format={ROOM_SERVICE_TIME_FORMAT}
                        suffixIcon={<IconTime className={styles.iconTime} />}
                        style={{ ...BOOKING_DATEPICKER_STYLE }}
                        minuteStep={BOOKING_MINUTE_STEP}
                      />
                      {errors?.arrivalTime ? (
                        <div className="error-input-msg">{errors.arrivalTime}</div>
                      ) : null}
                    </InputWrapper>

                    <InputWrapper containerClasses="col-12 col-md-6 col-lg-4">
                      <Label>Check-In date*</Label>
                      <DatePicker
                        className="date-picked-input"
                        size="large"
                        format={DEFAULT_FORMAT}
                        placeholder={DEFAULT_FORMAT}
                        suffixIcon={<IconCalendar />}
                        allowClear={false}
                        name="startDate"
                        value={values.startDate ? moment(values.startDate) : null}
                        onChange={($moment) => setFieldValue('startDate', $moment.format())}
                        style={BOOKING_DATEPICKER_ERRORED(errors?.startDate)}
                        disabled={!values?.editPolicy?.dates}
                        disabledDate={(current) => setDisableStartDates(
                          current,
                          bookingData.offerId,
                          values,
                        )}
                      />
                      {errors?.startDate && <Error error={errors.startDate} />}
                    </InputWrapper>

                    <InputWrapper containerClasses="col-12 col-md-6 col-lg-4">
                      <Label>Check-Out date*</Label>
                      <DatePicker
                        className="date-picked-input"
                        size="large"
                        format={DEFAULT_FORMAT}
                        placeholder={DEFAULT_FORMAT}
                        suffixIcon={<IconCalendar />}
                        allowClear={false}
                        name="endDate"
                        value={values.endDate ? moment(values.endDate) : null}
                        onChange={($moment) => setFieldValue('endDate', $moment.format())}
                        style={BOOKING_DATEPICKER_ERRORED(errors?.endDate)}
                        disabled={!values?.editPolicy?.dates}
                        disabledDate={(current) => checkToDisableOfferEndDates(
                          current, bookingData.offerId, bookingData.startDate,
                        )}
                      />
                      {errors?.endDate && <Error error={errors.endDate} />}
                    </InputWrapper>

                    <InputWrapper containerClasses="col-12 col-md-6 col-lg-4">
                      <Label>Room type*</Label>
                      <CustomSelect
                        disabled={!values?.editPolicy?.roomTypes}
                        name="roomType"
                        placeholder="Select room"
                        options={roomTypes}
                      />
                    </InputWrapper>
                  </div>
                  <div className="row">
                    <InputWrapper containerClasses="col-12 col-lg-8">
                      <Label>Comment</Label>
                      <Input
                        disabled={!values?.editPolicy?.mainGuestName}
                        type="text"
                        name="comment"
                        placeholder="Enter Comment"
                      />
                    </InputWrapper>
                  </div>

                  <hr className={styles.cardDivider} />
                  <div className={styles.buttons}>
                    <Link
                      to={bookingDetailsLink}
                      className="btn btn_bordered text-center t-600 btn_radius-28 btn_padded btn_room-md btn-uppercased"
                    >
                      Cancel
                    </Link>
                    <button
                      type="submit"
                      disabled={!dirty
                        || (!values?.editPolicy?.roomTypes
                          && !values?.editPolicy?.mainGuestName
                          && !values?.editPolicy?.dates)}
                      className="btn btn_radius-28 t-600 btn_md btn-uppercased btn_common"
                    >
                      Check availability and price
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
          {loading ? <Spinner spinnerClasses="spinner-primary" /> : suitableOffersData?.length > 0 ? (
            <div>
              <hr className={styles.cardDivider} />
              <div className="flex flex-between">
                <h4 className={styles.cardHeader6}>
                  Suitable offers
                </h4>
                {prevRef && nextRef ? (
                  <div>
                    <button
                      ref={prevRef}
                      type="button"
                      className={`
                  ${styles.sliderNavBtn} 
                  suitable-offer-slider-nav-btn
                  btn
                `}
                    >
                      <PrevPage />
                    </button>
                    <button
                      ref={nextRef}
                      type="button"
                      className={`
                  ${styles.sliderNavBtn} 
                  suitable-offer-slider-nav-btn
                  btn
                `}
                    >
                      <NextPage />
                    </button>
                  </div>
                ) : null}
              </div>
              <Swiper
                navigation={{
                  prevEl: prevRef?.current || undefined,
                  nextEl: nextRef?.current || undefined,
                }}
                loop={false}
                onInit={(swiper) => {
                  // eslint-disable-next-line no-param-reassign
                  swiper.params.navigation.prevEl = prevRef.current;
                  // eslint-disable-next-line no-param-reassign
                  swiper.params.navigation.nextEl = nextRef.current;
                  swiper.navigation.update();
                }}
                spaceBetween={20}
                slidesPerView={1}
                breakpoints={{
                  // when window width is >= 640px
                  640: {
                    slidesPerView: 1,
                  },
                  // when window width is >= 768px
                  768: {
                    slidesPerView: 2,
                  },
                  1024: {
                    slidesPerView: 3,
                  },
                }}
              >
                {suitableOffersData.map((offerItem, index) => (
                  <SwiperSlide
                    key={`dfsdfgdf${index}`}
                  >
                    <Offer
                      index={index}
                      selectButtonClick={() => suitableOfferSelectHandler(offerItem)}
                      offerItem={offerItem}
                    />
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          ) : null}
        </div>
      ) : <Spinner spinnerClasses="spinner-primary" />}
    </div>
  );
};

export default EditBooking;
