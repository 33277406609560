import React, { useRef } from 'react';
import SwiperCore, { Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import ArrowIcon from '../../../assets/img/icons/angle-arrow-down.svg';
import { PENALTY_TYPES } from '../../../constans/price-and-taxes';
import styles from './RoomItemSlider.module.scss';

// install Swiper components
SwiperCore.use([Navigation, Pagination]);

const RoomItemSlider = ({
  photosArr,
  wrapperStyles,
  slideStyles,
  withChip,
}) => {
  const prevRef = useRef(null);
  const nextRef = useRef(null);

  return (
    <>
      {photosArr && (
        <div className={`${styles.wrapper} ${wrapperStyles}`}>
          {withChip && (
            <span className={styles.chip}>
              {PENALTY_TYPES.NON_REFUNDABLE}
            </span>
          )}
          <Swiper
            navigation={{
              prevEl: prevRef?.current || undefined,
              nextEl: nextRef?.current || undefined,
            }}
            loop
            onInit={(swiper) => {
              // eslint-disable-next-line no-param-reassign
              swiper.params.navigation.prevEl = prevRef.current;
              // eslint-disable-next-line no-param-reassign
              swiper.params.navigation.nextEl = nextRef.current;
              swiper.navigation.update();
            }}
            spaceBetween={0}
            slidesPerView={1}
            pagination={{
              clickable: true,
              renderBullet: (index, className) => `<span class="${className} ${styles.paginBullet} drop-shadow-black"></span>`,
            }}
          >
            {photosArr.map((imageItem, index) => (
              <SwiperSlide className={`${styles.slideItem} ${slideStyles}`} key={`sdoo${index}`}>
                <img src={imageItem.file.link} alt={`img${index}`} />
              </SwiperSlide>
            ))}
            <div
              className={`${styles.navBtn} ${styles.navPrev} flex flex-v-center`}
              ref={prevRef}
            >
              <ArrowIcon
                className={`${styles.icon} drop-shadow-black`}
              />
            </div>
            <div
              className={`${styles.navBtn} ${styles.navNext} flex flex-v-center`}
              ref={nextRef}
            >
              <ArrowIcon
                className={`${styles.icon} drop-shadow-black`}
              />
            </div>
          </Swiper>
        </div>
      )}
    </>
  );
};

RoomItemSlider.defaultProps = {
  wrapperStyles: '',
  slideStyles: '',
};

export default RoomItemSlider;
