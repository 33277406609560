import React from 'react';
import SideNav from '../../SideNav/SideNav';
import { CUSTOMER_SETTINGS_NAV } from '../../../constans/links';

const CustomerSettingsWrapper = ({ containerStyles, children }) => (
  <div className={`${containerStyles} customer-page-wrapper container`}>
    <div className="h3-title t-700 seller-title">Settings</div>
    <div className="row">
      <SideNav links={CUSTOMER_SETTINGS_NAV} />
      <div className="col-12 col-lg-9">
        <div className="edit-form-wrapper edit-from-mobile">
          {children}
        </div>
      </div>
    </div>
  </div>
);

CustomerSettingsWrapper.defaultProps = {
  containerStyles: '',
};

export default CustomerSettingsWrapper;
