const addressComp = {
  street_number: 'short_name',
  route: 'long_name',
  locality: 'long_name',
  administrative_area_level_1: 'long_name',
  country: 'long_name',
};

const parseAddress = (place) => {
  const addressObj = {
    city: '',
    region: '',
    country: '',
    locationName: '',
    postalCode: '',
  };

  let address = '';
  place.address_components.forEach((item) => {
    const addressType = item.types[0];
    const component = addressComp[addressType];

    if (component) {
      if (addressType === 'street_number' || addressType === 'route') {
        address += !address ? item[component] : ` ${item[component]}`;
      }

      if (addressType === 'locality') {
        addressObj.city = item[component];
      }

      const areaLevels = ['administrative_area_level_1', 'administrative_area_level_2', 'administrative_area_level_3'];

      // if(isAreaLevel1 || isAreaLevel2 || isAreaLevel3) {
      if (areaLevels.includes(addressType)) {
        addressObj.region = addressObj.region ? addressObj.region : item[component];
      }

      if (addressType === 'country') {
        addressObj.country = item[component];
      }
    }
    if (addressType === 'postal_code') {
      addressObj.postalCode = item.short_name;
    }
  });

  // If city does not exist set political OR administrative_area_level_2 info
  if (!addressObj.city) {
    const city = place.address_components.find((item) => (
      // const addressType = item.types[0];
      item.types.find((itemAddr) => itemAddr === 'political' || itemAddr === 'administrative_area_level_2')
      // return addressType === 'political' || addressType === 'administrative_area_level_2';
    ));

    addressObj.city = city ? city.long_name : '';
  }

  addressObj.locationName = [address, addressObj.city, addressObj.region, addressObj.country]
    // filter array from empty values
    .filter((addrItem) => addrItem)
    .join(', ');

  return addressObj;
};

export default parseAddress;
