/* eslint-disable max-len */
const taxTypes = {
  FIXED: 'FIXED',
  PERCENTAGE: 'PERCENTAGE',
};

const offerPricePerGuestTypes = {
  PER_NIGHT: 'PER_NIGHT',
  ENTIRE_STAY: 'ENTIRE_STAY',
};

const OFFER_PRICE_PROVIDERS = {
  [offerPricePerGuestTypes.ENTIRE_STAY]: (price) => parseFloat(price),
  [offerPricePerGuestTypes.PER_NIGHT]: (price, lengthOfStay) => parseFloat(price) * lengthOfStay,
};

const sumTaxes = (taxes, type) => taxes
  .filter((tax) => tax.type === type)
  .reduce((result, tax) => result + parseFloat(tax.price), 0);

const BASE_NUMBER_OF_GUESTS = 1;
const DEFAULT_NUMBER_OF_GUESTS = 0;
const TOTAL_PERCENTS = 100;
const DEFAULT_NUMBER_OF_ROOMS = 1;

export default class OfferPriceCalculator {
  /**
   * @param {sequelize.Instance<OfferInstanceAttributes> | OfferInstance} offer
   * @param {number} lengthOfStay
   * @param {number} numberOfAdults = 0
   * @param {number} numberOfKids = 0
   * @param {number} numberOfRooms = 1
   * @param {TaxInstance[]} taxes = []
   * @return {*}
   */
  static getOfferPrice(
    offer,
    {
      lengthOfStay,
      numberOfAdults = DEFAULT_NUMBER_OF_GUESTS,
      numberOfKids = DEFAULT_NUMBER_OF_GUESTS,
      numberOfRooms = DEFAULT_NUMBER_OF_ROOMS,
      taxes = [],
    },
  ) {
    const basePriceProvider = OFFER_PRICE_PROVIDERS[offer?.basePriceType];
    const perGuestPriceProvider = OFFER_PRICE_PROVIDERS[offer?.pricePerGuestType];
    const numberOfAdditionalGuests = parseInt(numberOfAdults, 10) + parseInt(numberOfKids, 10) - BASE_NUMBER_OF_GUESTS;
    const pricePerGuest = perGuestPriceProvider(offer?.pricePerGuest, lengthOfStay);
    const basePrice = basePriceProvider(offer?.basePrice, lengthOfStay);
    const subTotalPrice = numberOfAdditionalGuests * pricePerGuest + basePrice * numberOfRooms;

    const fixedPartOfTax = sumTaxes(taxes, taxTypes.FIXED);
    const percentPartOfTax = sumTaxes(taxes, taxTypes.PERCENTAGE);

    const taxPrice = percentPartOfTax * (subTotalPrice / TOTAL_PERCENTS) + fixedPartOfTax;

    return { subTotalPrice, taxPrice };
  }
}
